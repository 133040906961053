import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import axiosInstance from '../components/axiosInstance';
const OrganizationSetup = () => {
    const [orgName, setOrgName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleContinue = async () => {
        if (!orgName.trim()) {
            setError('Organization name is required.');
            return;
        }
        setError('');

        try {
            const response = await axiosInstance.post('/v1/organizations/create', { // Use axios.post
                name: orgName, // Send data as an object
            });

            console.log("Response:", response); // Log the full response for debugging

            // Check for successful status (2xx) - Axios automatically throws errors for non-2xx statuses
            if (response.status >= 200 && response.status < 300) {
                navigate('/organizations');
            } else {
                // Handle unexpected success statuses if needed
                console.error("Unexpected successful status:", response.status);
                setError("An unexpected error occurred.");
            }
        } catch (error) {
            console.error('Error submitting organization:', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setError(`Server Error: ${error.response.data.message || error.response.status}`); // Display server error message or status code
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
                console.error("Response headers:", error.response.headers);

            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                setError('No response from server. Please check your network.');
                console.error("Request:", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                setError('An error occurred while setting up the request.');
                console.error('Error message:', error.message);
            }
        }
    };

    // ... (rest of the component code remains the same)
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
            <div className="mb-8">
                <img src="/your-logo.png" alt="Logo" className="h-20 w-auto" />
            </div>

            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                <input
                    type="text"
                    placeholder="Enter Organization Name"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {error && <p className="text-red-500 mt-2">{error}</p>}

                <button
                    onClick={handleContinue}
                    className="w-full mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default OrganizationSetup;