import React, { useEffect, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { MdOutlineArticle } from "react-icons/md";
import { MdIntegrationInstructions } from 'react-icons/md';
import { RiAccountCircleFill } from "react-icons/ri";
import { LuFolderEdit } from 'react-icons/lu';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, openSidebar } from '../redux/sidebarSlice';
import { CiCirclePlus } from "react-icons/ci";
import { LuChevronRightCircle, LuChevronLeftCircle } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { RiBillLine } from "react-icons/ri";
import axiosInstance from './axiosInstance';
import { getStoredValue } from '../services/logics.js';
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const { isOpen } = useSelector((state) => state.notification);
    const [isClicked, setIsClicked] = useState(false);
    const projectId = getStoredValue("activeProjectId");

    const isActive = (path) => location.pathname === `/organizations/${projectId}` + path;
    console.log(location.pathname);
    console.log(isActive('/assistants'));
    useEffect(() => {
        dispatch(openSidebar());
    }, [dispatch]);

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleLogout = () => {
        // try {
        //     const response = await axiosInstance.post(`/auth/jwt/logout`);

        //     localStorage.clear();
        //     setIsClicked(true);
        //     setTimeout(() => setIsClicked(false), 2000);
        //     localStorage.setItem("isLoggedIn", "false");
        //     window.location.href = "/signin";
        // }
        // catch {

        // }

        navigate(`/organizations`);
    };

    const textStyles = "text-base font-semibold";
    return (
        <div className={`flex-shrink-0 top-0 z-10 fixed h-screen ${isSidebarOpen ? 'w-[280px]' : 'w-[80px]'} bg-[#F3F3F3] text-white transition-all ease-in-out duration-300 flex flex-col justify-between`}
            style={{
                background: 'black',
                border: '1.15px solid',
                borderImageSource: 'linear-gradient(180deg, #0E151C 0%, #21905F 100%)',
            }}
        >
            {/* Navigation and Menu */}
            <div>
                <div className={`${(isOpen && location.pathname.includes("document")) ? 'pt-10' : 'pt-4'} mb-[50px] pl-[22px]`}>
                    <div className={`flex items-center pt-[4px]  mr-2 `}>
                        <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-lg font-semibold pb-2`}>Pragya.ai</span>
                    </div>
                </div>

                <ul className="space-y-2">
                    <li className={`${isActive('/assistants') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
                        <Link to="assistants" className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/assistants') ? 'bg-[#615C5C]  rounded-full text-[#ffffff] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}>
                            <FaPlus size={20} />
                            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Assistants</span>
                        </Link>
                    </li>
                    <li className={`${isActive('/files') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
                        <Link to="files" className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/files') ? 'bg-[#615C5C]  rounded-full text-[#ffffff] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}>
                            <MdOutlineArticle size={20} />
                            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Files</span>
                        </Link>
                    </li>
                    <li className={`${isActive('/knowledgebase') || location.pathname.startsWith('/draft-page/detail') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
                        <Link to="knowledgebase" className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/knowledgebase') || location.pathname.startsWith('/draft-page/detail') ? 'bg-[#615C5C]  rounded-full text-[#ffffff] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}>
                            <MdIntegrationInstructions size={20} />
                            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Knowledge Base</span>
                        </Link>
                    </li>
                    {/* <li className={`${isActive('/accounts-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
                        <Link to="/accounts-page" className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/accounts-page') ? 'bg-[#615C5C]  rounded-full text-[#ffffff] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}>
                            <RiAccountCircleFill size={20} />
                            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Accounts</span>
                        </Link>
                    </li> */}
                    {/* <li className={`${isActive('/billings') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
                        <Link to="/billings" className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/billings') ? 'bg-[#615C5C]  rounded-full text-[#ffffff] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}>
                            <RiBillLine size={20} />
                            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Billings</span>
                        </Link>
                    </li> */}
                </ul>
            </div>

            {/* Logout Button */}
            <button onClick={handleLogout} className="flex items-center justify-center mt-auto mb-4 px-4 py-2 text-[#000000] bg-[#EFEEEA]  rounded-full  duration-200 mx-4">
                <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Projects</span>
            </button>
        </div>
    );
};

export default Sidebar;
