import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-50 min-h-screen py-8 px-4 sm:px-8 lg:px-16">
            <div className="max-w-5xl mx-auto bg-white shadow-sm rounded-lg p-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
                    Privacy Policy
                </h1>
                <div className="space-y-6 text-gray-700">
                    <p>
                        Pragya is committed to protecting the privacy, confidentiality, and
                        security of your personal information. Your privacy is a top
                        priority, and we strive to safeguard your information from
                        unauthorized access. This Privacy Policy outlines how we collect,
                        use, and disclose your personal information in relation to our
                        services. By using our platform, you agree to the terms described
                        in this Privacy Policy.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        1. Information We Collect
                    </h2>
                    <h3 className="font-semibold">a) When You Use Our Website</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Usage Data:</strong> We collect information about how you
                            interact with our website, such as pages visited, time spent on
                            pages, and navigation paths.
                        </li>
                        <li>
                            <strong>Account Information:</strong> When you create an account,
                            we collect your name, email address, and company affiliation to
                            personalize your experience.
                        </li>
                        <li>
                            <strong>Payment Information:</strong> For paid subscriptions,
                            payment details are processed securely through third-party
                            providers and are not stored on our servers.
                        </li>
                    </ul>
                    <h3 className="font-semibold">b) When You Subscribe to Newsletters</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Contact Details:</strong> If you sign up for newsletters,
                            we collect your name and email address to send relevant updates.
                        </li>
                    </ul>
                    <h3 className="font-semibold">c) When You Use Our Services</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Server Logs:</strong> We track usage activity, including
                            IP address, browser type, and geo-location, to improve service
                            performance.
                        </li>
                        <li>
                            <strong>AI Interactions:</strong> Data input during interactions
                            with our AI tools, including queries and documents, may be
                            temporarily stored to enhance the performance and accuracy of our
                            models.
                        </li>
                    </ul>
                    <h3 className="font-semibold">d) When You Contact Support</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Support Conversations:</strong> We retain communication
                            records, such as emails and chat logs, to address your inquiries
                            effectively. These are deleted 90 days after your account is
                            closed.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        2. How We Use Your Information
                    </h2>
                    <p>
                        We collect and use your personal data to provide a secure,
                        efficient, and personalized experience, including:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>Service Delivery: To enable access to our AI-driven tools.</li>
                        <li>
                            Customer Support: To resolve issues and ensure optimal use of our
                            platform.
                        </li>
                        <li>Security: To detect and prevent unauthorized use.</li>
                        <li>
                            Improvements: To refine our AI models based on anonymized data.
                        </li>
                        <li>
                            Marketing: To inform you of new features, updates, and services.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        3. Data Retention
                    </h2>
                    <p>
                        Pragya retains personal information for as long as necessary to
                        provide the services you’ve requested and in accordance with
                        applicable laws. Once your account is deleted, most associated data
                        is removed within 90 days unless required for legal purposes.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        4. Third-Party Services
                    </h2>
                    <p>
                        We use reputable third-party services to enhance our platform’s
                        functionality, including:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Payment Processing:</strong> Razorpay.
                        </li>
                        <li>
                            <strong>Marketing and Analytics:</strong> Google Analytics and
                            similar platforms.
                        </li>
                        <li>
                            <strong>Cloud Storage:</strong> Azure Services for secure data
                            hosting.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        5. Your Rights
                    </h2>
                    <p>You have the following rights concerning your personal information:</p>
                    <ul className="list-disc ml-6">
                        <li><strong>Access and Portability:</strong> Request access and a copy of your personal data.</li>
                        <li><strong>Correction:</strong> Correct inaccuracies in your data.</li>
                        <li><strong>Deletion:</strong> Request the deletion of your data.</li>
                        <li>
                            <strong>Restriction and Objection:</strong> Request limitations or
                            object to certain uses.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        6. Cookies and Tracking Technologies
                    </h2>
                    <p>
                        Pragya uses cookies to enhance user experience. You can manage your
                        cookie preferences through browser settings, though disabling them
                        may limit functionality.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        7. Security Measures
                    </h2>
                    <p>
                        We employ industry-standard security measures such as encryption,
                        access controls, and monitoring to protect your data.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        8. Children’s Privacy
                    </h2>
                    <p>
                        Pragya’s services are not intended for individuals under 13 years of
                        age. We do not knowingly collect personal information from children.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        9. Changes to This Privacy Policy
                    </h2>
                    <p>
                        Pragya may update this Privacy Policy periodically. Continued use of
                        the services constitutes acceptance of the updated Privacy Policy.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        10. Contact Information
                    </h2>
                    <p>
                        For questions, concerns, or to exercise your rights, contact us at
                        [support email/contact details].
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
