import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from "../components/axiosInstance.js";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import { getStoredValue } from '../services/logics.js';

// import Navbar from '../profile/Navbar.jsx';
const CreateAssistant = () => {
    const navigate = useNavigate();
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistantId, setSelectedAssistantId] = useState(null);
    const [isEditingName, setIsEditingName] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [knowledgeBases, setKnowledgeBases] = useState([]); // State to store knowledge bases
    const [modelData, setModelData] = useState({
        assistant_name: '',
        firstMessage: '',
        instructions: '',
        provider: 'openai',
        model: 'gpt-4o-mini',
        knowledgebase: { id: '', name: '' } // Store both ID and name
    });
    // const projectId = useSelector((state) => state.project?.activeProjectId);
    const projectId = getStoredValue("activeProjectId");
    useEffect(() => {
        fetchKnowledgeBases();
        fetchAssistants();
    }, [projectId]);

    const fetchKnowledgeBases = async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/user/knowledge-bases/${projectId}`);
            setKnowledgeBases(response.data || []);
        } catch (error) {
            toast.error("Error fetching knowledge bases");
        }
    };

    const fetchAssistants = async () => {
        try {
            const response = await axiosInstance.get(`/v1/user/assistants/${projectId}/`);
            setAssistants(response.data || []);
        } catch (error) {
            toast.error("Error fetching assistants");
        }
    };

    const handleCreateAssistant = () => {
        const newAssistant = {

            assistant_name: `New Assistant`,
            firstMessage: '',
            instructions: '',
            model: 'gpt-4o-mini',
            knowledgebase: { id: '', name: '' }
        };
        setAssistants([...assistants, newAssistant]);
        setSelectedAssistantId(newAssistant.id);
        setModelData(newAssistant);
    };
    // Handle selecting an assistant
    const handleAssistantSelect = async (assistant) => {
        setSelectedAssistantId(assistant.id);
        navigate(`/organizations/${projectId}/assistants/${assistant.id}`);
        try {
            const response = await axiosInstance.get(`/v1/user/assistants/${assistant.id}`);
            const data = response.data;
            setModelData({
                assistant_name: data.assistant_name,
                firstMessage: data.first_message,
                instructions: data.instructions,
                model: data.model,
                knowledgebase: {
                    id: data.knowledgebase_id,
                    name: data.knowledgebase_name
                }
            });
        } catch (error) {
            toast.error("Error fetching assistant details");
        }
    };

    // Handle name change in header
    const handleNameChange = (newName) => {
        setModelData((prevData) => ({
            ...prevData,
            assistant_name: newName
        }));

        setAssistants((prevAssistants) =>
            prevAssistants.map((assistant) =>
                assistant.id === selectedAssistantId ? { ...assistant, assistant_name: newName } : assistant
            )
        );
    };

    // Handle publish (create or update)
    const handlePublish = async () => {
        const payload = {
            assistant_name: modelData.assistant_name,
            first_message: modelData.firstMessage,
            instructions: modelData.instructions,
            model: modelData.model,
            knowledgebase_id: modelData.knowledgebase.id,
            knowledgebase_name: modelData.knowledgebase.name
        };
        console.log(modelData.model);
        try {
            if (selectedAssistantId) {
                await axiosInstance.put(`/v1/update/assistants/${selectedAssistantId}`, payload);
                setAssistants((prevAssistants) =>
                    prevAssistants.map((asst) =>
                        asst.id === selectedAssistantId
                            ? { ...asst, ...payload }
                            : asst
                    )
                );
                toast.success("Assistant updated successfully");
            } else {
                const response = await axiosInstance.post(`/v1/user/assistants/${projectId}`, payload);
                const newAssistant = response.data;
                setAssistants((prevAssistants) => [...prevAssistants, newAssistant]);
                setSelectedAssistantId(newAssistant.id);
                toast.success("Assistant created successfully");
            }
            fetchAssistants();
        } catch (error) {
            toast.error("Error creating or updating assistant");
        }
    };

    // Handle delete assistant
    const handleDeleteAssistant = async (id) => {
        try {
            await axiosInstance.delete(`/v1/user/assistants/${id}`);
            setAssistants(assistants.filter((assistant) => assistant.id !== id));
            if (id === selectedAssistantId) {
                setSelectedAssistantId(null);
                setModelData({
                    assistant_name: '',
                    firstMessage: '',
                    instructions: '',
                    provider: 'openai',
                    model: 'gpt-4o-mini',
                    knowledgebase: { id: '', name: '' }
                });
            }
            toast.success("Assistant deleted successfully");
        } catch (error) {

            toast.error("Error deleting assistant");

        }
    };

    const selectedAssistant = assistants.find(
        (assistant) => assistant.id === selectedAssistantId
    );
    return (
        <div
            className={`flex absolute inset-0 bg-[#F9F9FB] flex-col ${isSidebarOpen ? 'ml-[280px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
        >
            {/* <Navbar




            /> */}
            <div className="h-screen bg-black text-white flex flex-col">
                {/* Header */}
                <header className="flex justify-between items-center p-4 bg-gray-800">
                    {selectedAssistant && (
                        <div className="flex items-center">
                            {isEditingName ? (
                                <input
                                    type="text"
                                    value={modelData.assistant_name || ''}
                                    onChange={(e) => handleNameChange(e.target.value)}
                                    onBlur={() => setIsEditingName(false)}
                                    className="bg-gray-700 text-white p-2 rounded"
                                    autoFocus
                                />
                            ) : (
                                <h1
                                    className="text-xl font-semibold cursor-pointer"
                                    onClick={() => setIsEditingName(true)}
                                >
                                    {modelData.assistant_name || 'New Assistant'}
                                </h1>
                            )}
                        </div>
                    )}
                    <div className="ml-auto">
                        <Link to={`chat`}>
                            <button className="bg-teal-600 text-white py-2 px-4 rounded">
                                Talk with Assistant
                            </button>
                        </Link>
                    </div>
                </header>

                {/* Main Content */}
                <div className="flex flex-1">
                    {/* Sidebar */}
                    <aside className="w-1/4 bg-gray-900 p-4">
                        <button
                            onClick={handleCreateAssistant}
                            className="bg-teal-500 text-white w-full py-2 mb-4 rounded"
                        >
                            Create Assistant
                        </button>
                        {assistants.map((assistant) => (
                            <div
                                key={assistant.id}
                                onClick={() => handleAssistantSelect(assistant)}
                                className="bg-gray-800 p-2 rounded mb-4 flex items-center justify-between cursor-pointer"
                            >
                                {/* Display the assistant name */}
                                <span>{assistant.assistant_name || 'Unnamed Assistant'}</span>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteAssistant(assistant.id);
                                    }}
                                    className="text-red-500 ml-2"
                                >
                                    <MdDelete size={20} className='text-white' />
                                </button>
                            </div>
                        ))}
                    </aside>

                    {/* Main Section */}
                    <main className="w-3/4 bg-gray-900 p-6">
                        {/* Model Configuration */}
                        <section className="bg-gray-800 p-6 rounded">
                            <h2 className="text-lg mb-4">Model</h2>
                            <div className="mb-4">
                                <label className="block text-gray-400 mb-2">First Message</label>
                                <input
                                    type="text"
                                    className="w-full p-2 bg-gray-700 text-white rounded"
                                    placeholder="First message content here"
                                    value={modelData.firstMessage}
                                    onChange={(e) =>
                                        setModelData({ ...modelData, firstMessage: e.target.value })
                                    }
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-400 mb-2">Instructions</label>
                                <textarea
                                    className="w-full h-80 p-2 bg-gray-700 text-white rounded"
                                    rows="4"
                                    placeholder="System prompt details"
                                    value={modelData.instructions}
                                    onChange={(e) =>
                                        setModelData({ ...modelData, instructions: e.target.value })
                                    }
                                />
                            </div>
                            <div className="mb-4 flex space-x-4">
                                {/* Provider Section */}
                                <div className="flex-1">
                                    <label className="block text-gray-400 mb-2">Provider</label>
                                    <select
                                        className="w-full p-2 bg-gray-700 text-white rounded"
                                        value={modelData.provider}
                                        onChange={(e) =>
                                            setModelData({ ...modelData, provider: e.target.value })
                                        }
                                    >
                                        <option value="openai">openai</option>
                                    </select>
                                </div>

                                {/* Model Section */}
                                <div className="flex-1">
                                    <label className="block text-gray-400 mb-2">Model</label>
                                    <select
                                        className="w-full p-2 bg-gray-700 text-white rounded"
                                        value={modelData.model}
                                        onChange={(e) => {
                                            const newModel = e.target.value;
                                            setModelData({ ...modelData, model: newModel })
                                        }}
                                    >
                                        <option value='gpt-4o-mini'>Gpt 4-o mini</option>
                                    </select>
                                </div>

                                {/* Knowledge Base Section */}
                                <div className="flex-1">
                                    <label className="block text-gray-400 mb-2">Knowledge Base</label>
                                    <select
                                        className="w-full p-2 bg-gray-700 text-white rounded"
                                        value={modelData.knowledgebase.id}
                                        onChange={(e) => {
                                            const selectedKb = knowledgeBases.find(
                                                (kb) => kb.id === e.target.value
                                            );
                                            setModelData({
                                                ...modelData,
                                                knowledgebase: {
                                                    id: selectedKb?.id || '',
                                                    name: selectedKb?.name || '',
                                                },
                                            });
                                        }}
                                    >
                                        <option value="">Select Knowledge Base</option>
                                        {knowledgeBases.map((kb) => (
                                            <option key={kb.id} value={kb.id}>
                                                {kb.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="flex justify-end">
                                <button
                                    onClick={handlePublish}
                                    className="bg-teal-500 py-2 px-4 rounded text-white"
                                >
                                    Publish
                                </button>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default CreateAssistant;
