import React from 'react';

const Sidebar = ({ activeTab, handleTabClick }) => {
    const topTabs = ["General", "Members", "Projects", "Billing"];
    const bottomTabs = [];

    return (
        <div className="w-64 bg-white shadow-md">
            <ul className="py-2">
                {topTabs.map((tabName) => (
                    <li
                        key={tabName}
                        className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${activeTab === tabName ? 'bg-gray-100 font-medium' : ''}`}
                        onClick={() => handleTabClick(tabName)}
                    >
                        {tabName}
                    </li>
                ))}
            </ul>
            <div className="p-4 border-t border-gray-200">
                <ul className="py-2">
                    {bottomTabs.map((tabName) => (
                        <li
                            key={tabName}
                            className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${activeTab === tabName ? 'bg-gray-100 font-medium' : ''}`}
                            onClick={() => handleTabClick(tabName)}
                        >
                            {tabName}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;