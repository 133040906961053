import React, { useState, useEffect } from "react";
import axiosInstance from "../components/axiosInstance";
import Group3 from "../assets/Group3.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';


function Billing() {
    const API_URL = process.env.REACT_APP_API_URL;
    const razorpay_id = process.env.RAZORPAY_ID;
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);


    const [amount, setAmount] = useState("");
    const [currency, setCurrency] = useState("INR"); // Default currency
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const API_URL = process.env.REACT_APP_API_URL;
    console.log(API_URL, 'nnnnnn');
    useEffect(() => {
        const fetchUserData = async () => {
            // assuming token is stored in localStorage
            try {
                const response = await axiosInstance.get(`/users/me`, {

                });
                console.log(response.status, 'nnnnnnnn')
                if (response.status !== 200) {
                    throw new Error('Failed to fetch user data');
                }

                const data = response.data;
                setUser(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const payload = {
            amount: amount * 100, // amount in smallest currency unit (e.g., paise for INR)
            currency: currency
        };

        try {
            const result = await axiosInstance.post(`${API_URL}/v1/payments/create_order/`, payload);

            if (!result) {
                alert("Server error. Are you online?");
                return;
            }

            const { amount, id: order_id, currency } = result.data;
            console.log(amount.toString());
            const options = {
                key: razorpay_id, // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                name: "Neuradynamics Pvt. Ltd",
                description: "Test Transaction",
                image: { Group3 },
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        order_id: order_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                    };

                    const result = await axiosInstance.post(`${API_URL}/v1/payments/verify_payment/`, data);


                    if (result.data === 200) {
                        toast.success("payment successfull");
                    }
                    else {
                        toast.error("payment verification failed")
                    }
                },
                prefill: {
                    name: "test",
                    email: user.email,
                    contact: "",
                },
                theme: {
                    color: "#61dafb",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error("Payment Error:", error);
            alert("An error occurred while processing your payment.");
        }
    }

    return (
        // <div
        //     className={`flex absolute inset-0  bg-[#EFEEEA]  flex-col  ${isSidebarOpen ? 'ml-[280px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
        // >
        // <div >

        <div className="bg-gray-900 text-white h-screen flex justify-center items-center">
            <div className="w-11/12 max-w-4xl bg-gray-800 rounded-lg p-6 shadow-md">
                <h2 className="text-xl font-bold mb-4">Billing & Usage <span className="text-sm font-normal text-blue-500">Trial</span></h2>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {/* Billing Email */}
                    <div className="col-span-2">
                        <div className="bg-gray-700 p-4 rounded-lg">
                            <label className="text-sm font-semibold">Billing Email</label>
                            <input
                                type="email"
                                value={user.email}
                                className="mt-2 w-full bg-gray-600 text-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                readOnly
                            />
                        </div>
                    </div>

                    {/* Payment Method */}
                    <div className="col-span-2">
                        <div className="bg-gray-700 p-4 rounded-lg">
                            <label className="text-sm font-semibold">Payment Method</label>
                            <div className="mt-2 flex items-center space-x-2">
                                <input
                                    type="number"
                                    placeholder="Amount"
                                    className="w-full bg-gray-600 text-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                                <select
                                    className="bg-gray-600 text-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                </select>
                                <button
                                    className="bg-green-600 w-full hover:bg-green-700 text-white px-4 py-2 rounded"
                                    onClick={displayRazorpay}
                                >
                                    Buy Credits
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-6 mt-6">
                    {/* Daily Usage */}
                    <div className="bg-gray-700 p-4 rounded-lg text-center">
                        <h3 className="text-sm font-semibold">Daily Usage</h3>
                        <p className="text-lg font-bold">$0.00</p>
                    </div>

                    {/* Monthly Usage */}
                    <div className="bg-gray-700 p-4 rounded-lg text-center">
                        <h3 className="text-sm font-semibold">Monthly Usage</h3>
                        <p className="text-lg font-bold">$0.00</p>
                    </div>

                    {/* Wallet Credits */}
                    <div className="bg-gray-700 p-4 rounded-lg text-center">
                        <h3 className="text-sm font-semibold">Wallet Credits</h3>
                        <p className="text-lg font-bold">$100.00</p>
                    </div>
                </div>

                <div className="bg-gray-700 mt-6 p-6 rounded-lg flex flex-col items-center justify-center text-center">
                    <p className="text-gray-400">No payment history found.</p>
                    <div className="mt-4 text-gray-500 text-sm">Have any questions about how billing works? Visit our <a href="#" className="text-blue-500">FAQ page</a> to get answers.</div>
                </div>
            </div>
        </div>
        // </div>
    );
}

export default Billing;
