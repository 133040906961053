import React, { useState, useEffect, useRef } from 'react';
import { FiPaperclip, FiImage, FiSend, FiMoreVertical } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from "../components/axiosInstance.js";
import { toast } from "react-toastify";
import { marked } from "marked";
import Cookies from 'js-cookie';

function ConversationInterface() {
    const { assistant_id } = useParams();
    const [conversations, setConversations] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.WEBSOCKET_URL;

    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [assistantmodel, setAssistantmodel] = useState('');
    const [instructions, setInstructions] = useState('');
    const [editingConversationId, setEditingConversationId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const messagesEndRef = useRef(null);
    // const token = Cookies.get('refresh_token');
    // const token = localStorage.getItem("access_token");
    const user_id = localStorage.getItem("user_id");

    // console.log(token);
    // Auto-scroll to the bottom when messages or loading state changes
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, loading]);

    // Fetch conversations for the assistant on component load
    useEffect(() => {
        fetchConversations();
        fetchAssistants();
    }, [assistant_id]);
    const dropdownRef = useRef(null);
    // Fetch conversations for a specific assistant
    const fetchConversations = async () => {
        try {
            const response = await axiosInstance.get(`/v1/assistants/${assistant_id}/conversations/`);
            setConversations(response.data);
        } catch (error) {
            toast.error('Error fetching conversations:', error);
        }
    };
    const fetchAssistants = async () => {
        try {
            const response = await axiosInstance.get(`/v1/user/assistants/${assistant_id}`);
            setAssistantmodel(response.data.model || '');
            setInstructions(response.data.instructions || '');
            console.log(response.data);
        } catch (error) {
            toast.error("Error fetching assistants");
        }
    };
    // Create a new conversation
    const handleCreateConversation = async () => {
        try {
            const response = await axiosInstance.post(`/v1/assistants/${assistant_id}/conversations/`, {
                title: `Conversation ${conversations.length + 1}`,
            });
            setConversations([...conversations, { id: response.data.conversation_id, title: response.data.title || 'New chat' }]);
            setSelectedConversationId(response.data.conversation_id);
            setMessages([]);
        } catch (error) {
            toast.error('Error creating conversation');
        }
    };

    // Fetch messages for a selected conversation
    const handleConversationSelect = async (conversation) => {
        setSelectedConversationId(conversation.id);
        setIsLoadingMessages(true);
        try {
            const response = await axiosInstance.get(`/v1/conversations/${conversation.id}/messages`);
            setMessages(response.data);
            console.log(messages);
        } catch (error) {
            toast.error('Error fetching messages');
            setMessages([]); // Set to empty if an error occurs
        } finally {
            setIsLoadingMessages(false);
        }
    };

    // Updated WebSocket Connection setup within handleSendMessage or App component
    // const handleSendMessage = () => {
    //     if (!newMessage || !selectedConversationId) return;

    //     const userMessage = { role: 'user', content: newMessage };
    //     setMessages((prevMessages) => [...prevMessages, userMessage]);

    //     setNewMessage('');
    //     const socket = new WebSocket(`ws://localhost:8000/v1/assistants/${assistant_id}/conversations/${selectedConversationId}/stream/?token=${token}`);

    //     socket.onopen = () => {
    //         socket.send(newMessage);
    //     };

    //     socket.onmessage = (event) => {
    //         const messageFromServer = event.data;
    //         if (messageFromServer !== '[DONE]') {
    //             // Update the last bot message or add as new if not appending
    //             setMessages((prevMessages) => {
    //                 const lastMessage = prevMessages[prevMessages.length - 1];
    //                 if (lastMessage && lastMessage.role === 'bot') {
    //                     // Append to last message if it was also from the bot
    //                     return [...prevMessages.slice(0, prevMessages.length - 1),
    //                     { ...lastMessage, content: `${lastMessage.content}\n${marked.parse(messageFromServer)}` }];
    //                 } else {
    //                     // Add a new message when the last message was not from the bot
    //                     return [...prevMessages, { role: 'bot', content: marked.parse(messageFromServer) }];
    //                 }
    //             });
    //         } else {
    //             // Handle completion of the message stream
    //             console.log('Message streaming complete.');
    //         }
    //     };

    //     socket.onerror = (error) => {
    //         console.error('WebSocket error:', error);
    //     };

    //     socket.onclose = () => {
    //         console.log('WebSocket connection closed');
    //     };
    // };
    const handleSendMessage = () => {
        if (!newMessage || !selectedConversationId) return;

        const userMessage = { role: 'user', content: newMessage };

        // Optimistically update the UI with the user's message
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setNewMessage('');

        // Open a WebSocket connection
        const socket = new WebSocket(
            `wss://api.pragya.neuradynamics.ai/v1/assistants/${assistant_id}/conversations/${selectedConversationId}/stream/?user_id=${user_id}`
        );

        // Track the response message being built
        let currentBotMessage = '';

        // Send the user message to the backend when the connection opens
        socket.onopen = () => {
            socket.send(newMessage);
        };
        setLoading(true);

        // Handle streaming response from the backend
        socket.onmessage = (event) => {
            setLoading(false);
            const partialResponse = event.data;

            if (partialResponse === '[DONE]') {
                // Append the final bot message and close the WebSocket
                // setMessages((prevMessages) => [
                //     ...prevMessages,
                //     { role: 'bot', content: currentBotMessage },
                // ]);
                socket.close();
            } else {
                // Update the response message being built
                currentBotMessage += partialResponse;

                // Optimistically update the UI with the bot's partial response
                setMessages((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === 'bot') {
                        // Update the last bot message with the new chunk
                        const updatedMessages = [...prevMessages];
                        updatedMessages[updatedMessages.length - 1].content = currentBotMessage;
                        return updatedMessages;
                    } else {
                        // Add a new bot message if none exists
                        return [...prevMessages, { role: 'bot', content: currentBotMessage }];
                    }
                });
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            if (error.code === 4000) {
                toast.error("Token limit exceeded");
            }
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);

            // Check for specific close codes or reasons
            if (event.code === 4000) {
                toast.error('Token limit exceeded! Please subscribe to continue.');
            } else if (event.code === 4001) {
                toast.error('An unexpected error occurred.');
            } else {
                console.log('WebSocket closed with code:', event.code, 'reason:', event.reason);
            }
        };
    };


    // Send a message to the selected conversation and get bot response
    // const handleSendMessage = async () => {
    //     if (!newMessage || !selectedConversationId) return;

    //     const userMessage = { role: 'user', content: newMessage };

    //     // Optimistically update the UI with the user's message
    //     setMessages((prevMessages) => [...prevMessages, userMessage]);
    //     setNewMessage('');
    //     setLoading(true);
    //     try {
    //         // Send the message to the backend
    //         const response = await axiosInstance.post(`/v1/assistants/${assistant_id}/conversations/${selectedConversationId}/messages/`, {
    //             content: newMessage,
    //             instruction: instructions,
    //             model: assistantmodel
    //         });

    //         // Add the bot's response to the messages
    //         const botMessage = { role: 'bot', content: response.data.response };
    //         setMessages((prevMessages) => [...prevMessages, botMessage]);
    //     } catch (error) {
    //         console.error('Error sending message:', error);
    //         if (error.status === 400) {
    //             toast.error("Token limit exceeded");
    //         }

    //     } finally {
    //         setLoading(false); // Hide the loader
    //     }
    // };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close the dropdown if clicked outside
            if (!event.target.closest('.dropdown-menu')) {
                setOpenDropdownId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (conversationId) => {
        setOpenDropdownId(openDropdownId === conversationId ? null : conversationId);
    };

    // Handle delete conversation
    const handleDeleteConversation = async (conversationId) => {
        try {
            await axiosInstance.delete(`/v1/assistants/${assistant_id}/conversations/${conversationId}`);
            setConversations(conversations.filter(conv => conv.id !== conversationId));
            if (selectedConversationId === conversationId) {
                setSelectedConversationId(null);
                setMessages([]);
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
        setOpenDropdownId(null);
    };

    // Handle rename conversation

    const handleRenameConversation = (conversationId, currentTitle) => {
        setEditingConversationId(conversationId);
        setEditedTitle(currentTitle);
    };

    const handleUpdateConversation = async (conversationId, newTitle) => {
        try {
            await axiosInstance.patch(`/v1/conversations/${conversationId}/title/`, {
                new_title: newTitle,
            });
            setConversations((prev) =>
                prev.map((conv) => (conv.id === conversationId ? { ...conv, title: newTitle } : conv))
            );
        } catch (error) {
            console.error('Error updating conversation title:', error.response?.data || error.message);
        } finally {
            setEditingConversationId(null);
        }
    };

    const handleBlurOrSubmit = (conversationId) => {
        if (editedTitle.trim()) {
            handleUpdateConversation(conversationId, editedTitle);
        } else {
            setEditingConversationId(null);
        }
    };
    // Handle share conversation
    const handleShareConversation = (conversationId) => {
        alert(`Sharing conversation ${conversationId}`);
        setOpenDropdownId(null);
    };

    return (
        <div className={`flex absolute inset-0 bg-[#F9F9FB] ${isSidebarOpen ? 'ml-[285px]' : 'ml-[80px]'} transition-all duration-300`}>
            <aside className="w-1/4 bg-[#a505a8] p-4 h-screen flex flex-col items-center">
                <button
                    onClick={handleCreateConversation}
                    className="bg-white text-fuschia w-full py-3 mb-6 rounded"
                >
                    Create Conversation
                </button>
                {conversations.map((conversation) => (

                    <div
                        key={conversation.id}
                        onClick={() => handleConversationSelect(conversation)}
                        className={`bg-[#8a0589] p-3 rounded mb-4 flex items-center justify-between cursor-pointer w-full text-white ${selectedConversationId === conversation.id ? 'border-2 border-white' : ''}`}
                    >{editingConversationId === conversation.id ? (
                        <input
                            type="text"
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            onBlur={() => handleBlurOrSubmit(conversation.id)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleBlurOrSubmit(conversation.id);
                                }
                            }}
                            autoFocus
                            className="flex-1 bg-white text-black px-2 py-1 rounded"
                        />
                    ) : (
                        <span>{conversation.title}</span>
                    )}
                        <div className="relative">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent click event from bubbling up
                                    toggleDropdown(conversation.id);
                                }}
                                className="ml-2 dropdown-button"
                            >
                                <FiMoreVertical />
                            </button>

                            {/* Dropdown menu */}
                            {openDropdownId === conversation.id && (
                                <div
                                    className="absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-lg z-20 dropdown-menu"
                                    style={{ top: '100%', right: '0' }}
                                >
                                    <ul className="text-gray-700">
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteConversation(conversation.id);
                                            }}
                                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                        >
                                            Delete
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRenameConversation(conversation.id);
                                            }}
                                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                        >
                                            Rename
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleShareConversation(conversation.id);
                                            }}
                                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                                        >
                                            Share
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>


                ))}

            </aside>
            <main className="flex-1 h-full bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center pl-3 pr-6">
                <div className="w-full max-w-8xl bg-white shadow-xl rounded-2xl overflow-hidden h-full flex flex-col">
                    <div className="bg-[#a505a8] text-white p-6">
                        <h2 className="text-2xl font-bold">Conversation Interface</h2>
                        {selectedConversationId && <p className="text-sm opacity-80">Conversation ID: {selectedConversationId}</p>}
                    </div>

                    <div className="p-6 flex-1 overflow-y-auto">
                        {isLoadingMessages ? (
                            <p>Loading messages...</p>
                        ) : messages.length > 0 ? (
                            <div className="space-y-4">
                                {messages.map((message, index) => (
                                    <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                        <div className={` ${message.role === 'user' ? 'bg-[#a505a8]' : 'bg-black'} text-white rounded-2xl p-4 max-w-md`}
                                            dangerouslySetInnerHTML={{ __html: message.content }}>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: message.citations }}></div>
                                    </div>
                                ))} {loading && (
                                    <div className="flex justify-center">
                                        <div className="loader border-t-4 border-[#a505a8] border-solid rounded-full w-6 h-6 animate-spin"></div>
                                    </div>
                                )}
                                <div ref={messagesEndRef}></div>
                            </div>
                        ) : (
                            <p>No messages in this conversation.</p>
                        )}
                    </div>

                    <div className="border-t border-gray-100 p-6">
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSendMessage();
                                    }
                                }}
                                placeholder="Type your message here..."
                                className="flex-1 px-6 py-3 bg-gray-50 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#a505a8] focus:border-transparent transition-all"
                            />
                            <button
                                onClick={handleSendMessage}
                                className="px-6 py-3 bg-[#a505a8] text-white rounded-xl hover:bg-[#8a0589] transition-colors duration-200 flex items-center space-x-2"
                            >
                                <span>Send</span>
                                <FiSend className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default ConversationInterface;
