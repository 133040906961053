import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedOrg: null,
    organizations: [],
    loading: false,
    error: null,
};

const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        setOrganizations: (state, action) => {
            state.organizations = action.payload;
        },
        setSelectedOrg: (state, action) => {
            state.selectedOrg = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { setOrganizations, setSelectedOrg, setLoading, setError } = orgSlice.actions;
export default orgSlice.reducer;