import React, { useState } from 'react';
import { toast } from "react-toastify";
import axiosInstance from "../components/axiosInstance.js";
const InviteModal = ({ isOpen, onClose, organization_id }) => {
    const [emails, setEmails] = useState('');
    const [role, setRole] = useState('MEMBER');
    const [inviteToProject, setInviteToProject] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleInvite = async () => {
        try {
            const response = await axiosInstance.post(`/v1/invites/send`, {
                email: emails,
                organisation_id: organization_id,
                role: role
            }); // Replace with your API endpoint
            console.log(response);
            toast.success("Invitation Send");
            // setinvite(response.data);
        } catch (err) {
            if (err.response.data.detail === "Failed to send Invite: user already exists in Organisation") {
                toast.error("User already exists in organization")
            }
            else {
                toast.error("Error sending invite:", err);
            }
        } finally {
            setLoading(false);
        }
        onClose(); // Close the modal after inviting
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-8 w-1/2 max-w-md">
                <h2 className="text-lg font-semibold mb-4">Invite team members</h2>

                <div className="mb-4">
                    <label htmlFor="emails" className="block text-gray-700 font-medium mb-2">
                        Emails
                    </label>
                    <input
                        type="text"
                        id="emails"
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                        placeholder="jane@example.com, joe@example.com..."
                        className="w-full border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                <div className="mb-4 flex items-center">
                    <div className="mr-4">
                        <label htmlFor="role" className="block text-gray-700 font-medium mb-2">
                            Role
                        </label>
                        <select
                            id="role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="MEMBER">Member</option>
                            <option value="OWNER">Owner</option>
                        </select>
                    </div>
                    <div className="flex items-center">
                        {/* <input
                            type="checkbox"
                            id="inviteToProject"
                            checked={inviteToProject}
                            onChange={(e) => setInviteToProject(e.target.checked)}
                            className="mr-2"
                        /> */}
                        <label htmlFor="inviteToProject" className="text-gray-700">
                            Invite to default project
                        </label>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded mr-2">
                        Cancel
                    </button>
                    <button onClick={handleInvite} className="bg-green-500 hover:bg-green-700 text-white font-medium py-2 px-4 rounded">
                        Invite
                    </button>
                </div>
            </div>
        </div>
    );
};
export default InviteModal;