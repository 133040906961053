// projectSlice.js
import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
    name: 'project',
    initialState: { activeProjectId: null },
    reducers: {
        setActiveProjectId: (state, action) => {
            state.activeProjectId = action.payload;
            localStorage.setItem('activeProjectId', action.payload);
        },
    },
});

export const { setActiveProjectId } = projectSlice.actions;
export default projectSlice.reducer;
