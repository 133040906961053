import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../components/axiosInstance.js";
import CreateProject from "./Createproject.jsx";
import codesandbox from "../assets/codesandbox.svg"
import { useNavigate } from "react-router-dom";


const fetchUsers = async (searchTerm) => {
    const response = await new Promise((resolve) => setTimeout(() => resolve([
        { organization_id: 1, full_name: 'John Doe', role: 'MEMBER', email: 'john.doe@example.com' },
        { organization_id: 2, full_name: 'Jane Smith', role: 'OWNER', email: 'jane.smith@example.com' },
    ]), 1000)); // Simulate 1 second delay

    // Filter users based on searchTerm
    return response.filter((user) => user.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
};
const Project = ({ organization_id, userorganization_id, role }) => {
    const navigate = useNavigate();
    const [projectusersList, setProjectusersList] = useState([]);
    const [selectedPdfs, setSelectedPdfs] = useState([]);
    const [Projects, setProjects] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [currentProjectid, setCurrentProjectid] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [ProjectName, setProjectName] = useState('');
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingProject, setEditingProject] = useState(null);
    const [newProjectName, setNewProjectName] = useState('');
    const [loading, setLoading] = useState(false);
    const [kbno, setkbno] = useState(null);
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    const [folders, setFolders] = useState([]);
    const [folderPdfs, setFolderPdfs] = useState({});
    const [expandedFolders, setExpandedFolders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [newUserRole, setNewUserRole] = useState("MEMBER");
    const [members, setMembers] = useState([]);

    // Debounced search functionality (using setTimeout)
    const [organizationUsers, setOrganizationUsers] = useState([]);
    // const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUserOrganizationId, setSelectedUserOrganizationId] = useState(null);

    // const handleUserSelect = (user) => {
    //     setSearchTerm(`${user.full_name} (${user.email})`);
    //     setSelectedUserOrganizationId(user.userorganizationid);
    // };

    useEffect(() => {
        const fetchOrganizationUsers = async () => {
            try {
                const response = await axiosInstance.get(`/v1/organizations/${organization_id}/users`);
                setOrganizationUsers(response.data);
            } catch (error) {
                console.error("Failed to fetch users", error);
            }
        };

        if (showPdfModal) {
            fetchOrganizationUsers();
        }
    }, [showPdfModal, API_URL]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = organizationUsers.filter(user =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers([]);
        }
    }, [searchTerm, organizationUsers]);

    const handleUserSelect = (user) => {
        setSearchTerm(`${user.full_name} (${user.email})`);
        setSelectedUserOrganizationId(user.id);
        setFilteredUsers([]);
        console.log("Selected User:", user);
    };

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            if (searchTerm) {
                const fetchedUsers = await fetchUsers(searchTerm);
                setUsers(fetchedUsers);
            } else {
                setUsers([]); // Clear results if search term is empty
            }
        }, 500); // Debounce time (wait 500ms after last keystroke)

        return () => clearTimeout(timeoutId); // Cleanup function on unmount
    }, [searchTerm]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    useEffect(() => {

        fetchProjects();
    }, [organization_id, userorganization_id]);



    // Fetch ungrouped PDFs
    const handleAddUser = async (userOrganizationId, role) => {
        console.log(role, 'rrrrrrrrrrrr', userOrganizationId, 'rttttttttt', userorganization_id);
        try {
            const response = await axiosInstance.post(`/v1/projects/${currentProjectid}/add-user`, {
                userorganization_id: userorganization_id,
                addeduserorgid: userOrganizationId,
                role: role
            });
            toast.success("User added successfully!");
        } catch (error) {
            console.error("Failed to add user:", error);
            if (error.status === 400) {
                toast.error("User already exist");
            }
            else {
                toast.error("Failed to add user. Please try again.");
            }
        }
    };

    // Fetch PDFs in a folder
    const fetchProjectusers = async (project_id) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/projects/${project_id}/users`);
            setProjectusersList(response.data);
            console.log(response.data);
        } catch (error) {
            toast.error("Error fetching folder PDFs");
        }
    };

    // Toggle folder expansion
    const toggleFolder = (folderId) => {
        if (expandedFolders.includes(folderId)) {
            setExpandedFolders(expandedFolders.filter((id) => id !== folderId));
        } else {
            setExpandedFolders([...expandedFolders, folderId]);
            if (!folderPdfs[folderId]) {
                // fetchFolderPdfs(folderId);
            }
        }
    };


    const fetchProjects = async () => {
        setLoading(true);
        try {
            console.log(userorganization_id);
            const response = await axiosInstance.get(`${API_URL}/v1/projects/${organization_id}/user/${userorganization_id}/`);
            setProjects(response.data);
            console.log(response.data);
            setUsers(response.data);
            setIsProjectsLoaded(true);
        } catch (error) {
            toast.error("Error fetching projects");
        }
        setLoading(false);
    };
    // Handle checkbox change
    const handleCheckboxChange = (pdfId) => {
        setSelectedPdfs((prev) =>
            prev.includes(pdfId)
                ? prev.filter((id) => id !== pdfId)
                : [...prev, pdfId]
        );
    };

    const handleRoleChange = async (userorgId, newRole, project_id) => {
        console.log(userorgId, project_id, userorganization_id);
        try {
            // Update the role in the backend
            const response = await axiosInstance.patch(
                `/v1/projects/${project_id}/update-role`, // Replace with the actual API endpoint
                {
                    target_userorganization_id: userorgId,
                    owner_userorgid: userorganization_id,
                    role: newRole
                }
            );

            // On success, update the role in the frontend state
            if (response.status === 204) {
                setMembers(prevMembers =>
                    prevMembers.map(member =>
                        member.userorg_id === userorgId ? { ...member, role: newRole } : member
                    )
                );
                toast.success("Role updated successfully!");
            } else {
                throw new Error("Failed to update role.");
            }
        } catch (error) {
            console.error("Error updating role:", error);
            toast.error(error.response?.data?.message || "An error occurred while updating the role.");
        }
    };
    const handleCreateProject = async () => {
        try {
            console.log(userorganization_id);

            const response = await axiosInstance.post(`${API_URL}/v1/projects/create`, {
                name: ProjectName,
                organization_id: organization_id,
                user_organization_id: userorganization_id

            });



            const newProject = {
                id: response.data.id,
                name: ProjectName,

            };
            setProjects([...Projects, newProject]);
            setShowCreateModal(false);
            setSelectedPdfs([]);
        } catch (error) {
            toast.error("Error creating Project:", error);
        }
    };

    // Show PDFs of a specific knowledge base
    const handleShowProject = (Project) => {
        fetchProjectusers(Project);
        setCurrentProjectid(Project);
        setShowPdfModal(true);
    };

    const handleDeleteProject = async (id) => {
        try {
            await axiosInstance.delete(`/v1/projects/${id}/user/${userorganization_id}`);
            setProjects(Projects.filter((kb) => kb.id !== id));
            toast.success("Project deleted successfully");
        } catch (error) {
            toast.error("Error deleting knowledge base");
        }
    };
    const handleEditProject = (kb) => {
        setEditingProject(kb);
        setNewProjectName(kb.name);
        setShowEditModal(true);
    };
    const removeUser = async (userproject_id, project_id) => {
        console.log(userproject_id, project_id);
        try {
            // Optimistically update the UI by filtering out the user
            setProjectusersList(prevUsers => prevUsers.filter(user => user.id !== userproject_id));

            // Make the API call to remove the user from the backend
            await axiosInstance.post(`/v1/projects/${project_id}/remove-user`, {
                target_userproject_id: userproject_id,
                owner_userorgid: userorganization_id
            });

            toast.success(`User with ID ${userproject_id} removed successfully.`);
        } catch (err) {
            // Revert the optimistic update in case of an error
            toast.error('Failed to remove user from project')
            console.error("Error removing user:", err);
        }
    };
    // Save Edited Knowledge Base
    const handleSaveEdit = async () => {
        if (!editingProject) return;
        try {
            await axiosInstance.put(`${API_URL}/v1/user/update-knowledge-base/${editingProject.id}`, {
                name: newProjectName
            });
            setProjects(Projects.map((kb) =>
                kb.id === editingProject.id ? { ...kb, name: newProjectName } : kb
            ));
            setShowEditModal(false);
            toast.success("Knowledge base updated successfully");
        } catch (error) {
            toast.error("Error updating knowledge base");
        }
    };
    if (loading) {
        // Show a loader while data is being fetched
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-gray-500 text-xl">Loading...</div>
            </div>
        );
    }
    return (
        // <div
        //     className={`flex absolute inset-0  bg-[#EFEEEA]  flex-col  ${isSidebarOpen ? 'ml-[380px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
        // >
        <div>
            <div className="m-5  mb-5">


                {/* Create Knowledge Base Card */}


                {/* List of Knowledge Bases */}
                {(Projects?.length === 0) ? (
                    <CreateProject onCreateProject={() => setShowCreateModal(true)} />
                ) : (
                    <div >
                        <div className="flex items-center justify-between">
                            {/* Text aligned to the left */}
                            <h5 className="text-xl font-semibold">Create Project</h5>

                            {role !== 'MEMBER' && (
                                <button className="bg-black text-white py-2 px-4 rounded" onClick={() => setShowCreateModal(true)}>
                                    New Project
                                </button>)}
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">


                            {Projects?.map((kb) => (

                                <div
                                    key={kb.id}
                                    className="cursor-pointer bg-white shadow-md rounded-lg p-6 hover:bg-gray-100"


                                >

                                    <div className="flex items-center space-x-4">
                                        {/* Icon */}
                                        <img
                                            src={codesandbox}
                                            alt="Success Tick"
                                            className="h-[45px] w-[45px]"
                                        />

                                        {/* Text Content */}
                                        <div>
                                            <h5 className="text-lg font-semibold" onClick={() => navigate(`/organizations/${kb.id}/assistants`)}>{kb.name}</h5>
                                            <p
                                                className="text-sm text-gray-500"
                                                onClick={() => handleShowProject(kb.id)}
                                            >
                                                Project Users
                                            </p>
                                        </div>
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="mt-4">
                                        {/* Edit Button */}
                                        {/* <button
                                            className="bg-black text-white px-2 py-1 mx-2 rounded "
                                            onClick={() => handleEditProject(kb)}
                                        >
                                            Edit
                                        </button> */}

                                        {/* Delete Button */}
                                        <button
                                            className="bg-black text-white px-2 py-1 rounded "
                                            onClick={() => handleDeleteProject(kb.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                )}


                {/* Modal for Creating Knowledge Base */}
                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Create Knowledge Base</h3>

                            {/* Knowledge Base Name Input */}
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">Knowledge Base Name</label>
                                <input
                                    type="text"
                                    value={ProjectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    placeholder="Enter a name"
                                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                                />
                            </div>

                            {/* Ungrouped PDFs */}
                            {/* <div className="mb-4">
                                <h4 className="font-medium">Files</h4>
                                <div className="max-h-60 overflow-y-auto">
                                    {pdfList.map((pdf) => (
                                        <div key={pdf.id} className="flex items-center mb-2">
                                            <input
                                                type="checkbox"
                                                className="mr-3"
                                                checked={selectedPdfs.includes(pdf.id)}
                                                onChange={() => handleCheckboxChange(pdf.id)}
                                            />
                                            <label>{pdf.name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div> */}

                            {/* Folders */}
                            <div className="mb-4">
                                <h4 className="font-medium">Folders</h4>
                                {folders.map((folder) => (
                                    <div key={folder.id}>
                                        <div
                                            className="cursor-pointer text-black"
                                            onClick={() => toggleFolder(folder.id)}
                                        >
                                            {folder.name}
                                        </div>
                                        {expandedFolders.includes(folder.id) && (
                                            <div className="ml-4">
                                                {folderPdfs[folder.id] ? (
                                                    folderPdfs[folder.id].map((pdf) => (
                                                        <div key={pdf.id} className="flex items-center mb-2">
                                                            <input
                                                                type="checkbox"
                                                                className="mr-3"
                                                                checked={selectedPdfs.includes(pdf.id)}
                                                                onChange={() => handleCheckboxChange(pdf.id)}
                                                            />
                                                            <label>{pdf.name}</label>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>Loading...</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex justify-end mt-6">
                                <button
                                    className="bg-black text-white px-4 py-2 rounded mr-3"
                                    onClick={handleCreateProject}

                                >
                                    Create
                                </button>
                                <button
                                    className="bg-white text-black px-4 py-2 rounded border-2 border-current"
                                    onClick={() => setShowCreateModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal to Show PDFs in a Knowledge Base */}
                {showPdfModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-3xl p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4"> {/* Combined search and dropdown section */}
                                <div className="relative w-2/3 mr-4">
                                    <input
                                        type="text"
                                        className="w-full px-4 py-2 border rounded focus:outline-none"
                                        placeholder="Search users..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    {filteredUsers.length > 0 && (
                                        <ul className="absolute bg-white border rounded shadow-md mt-1 w-full max-h-40 overflow-y-auto z-10">
                                            {filteredUsers.map(user => (
                                                <li
                                                    key={user.id}
                                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => {
                                                        handleUserSelect(user);
                                                        setSearchTerm(`${user.full_name} (${user.email})`);
                                                    }}
                                                >
                                                    {user.full_name} ({user.email})
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <select
                                    value={newUserRole}
                                    onChange={(e) => setNewUserRole(e.target.value)}
                                    className="border rounded px-2 py-1 mr-4"
                                >
                                    <option value="OWNER">Owner</option>
                                    <option value="MEMBER">Member</option>
                                </select>
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
                                    onClick={() => handleAddUser(selectedUserOrganizationId, newUserRole)}
                                // Disable the button if no user is selected
                                >
                                    Add User
                                </button>
                            </div>
                            {projectusersList && projectusersList?.map(member => (
                                <div key={member?.organization_id} className="flex items-center p-4 border-b last:border-b-0">
                                    <div className="rounded-full bg-gray-300 w-10 h-10 flex items-center justify-center mr-4">
                                        {member.full_name.charAt(0).toUpperCase()}
                                    </div>
                                    <div className="flex-1">
                                        <p className="font-medium">{member.full_name} {member.role === 'OWNER' && <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">You</span>}</p>
                                        <p className="text-gray-500 text-sm">{member.email}</p>
                                    </div>
                                    <div>
                                        <select
                                            value={member.role}
                                            onChange={(e) => handleRoleChange(member.userorg_id, e.target.value, member.project_id)}
                                            className="border rounded px-2 py-1"
                                        >
                                            <option value="OWNER">Owner</option>
                                            <option value="MEMBER">Member</option>
                                        </select>
                                    </div>
                                    <div>
                                        {member.role === 'OWNER' ? (
                                            <button className="text-gray-500" >Leave</button>
                                        ) : (
                                            <button className="text-red-500" onClick={() => removeUser(member.id, member.project_id)}>Remove</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <button onClick={() => setShowPdfModal(false)} className="bg-gray-500 text-white px-4 py-2 rounded mt-4">
                                Close
                            </button>
                        </div>
                    </div>
                )}
                {showEditModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Edit Knowledge Base</h3>
                            <input
                                type="text"
                                value={newProjectName}
                                onChange={(e) => setNewProjectName(e.target.value)}
                                placeholder="Enter a new name"
                                className="w-full px-4 py-2 border rounded mb-4"
                            />
                            <div className="flex justify-end">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={handleSaveEdit}
                                >
                                    Save
                                </button>
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded"
                                    onClick={() => setShowEditModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
};

export default Project;
