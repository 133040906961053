import React, { useState, useEffect } from "react";
import { FaFolder, FaPlus, FaFile } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import axiosInstance from "../components/axiosInstance";
import { useDispatch, useSelector } from 'react-redux';
import DocumentsPage from "../components/Uploadfile";
import FolderDocumentsPage from "../components/Filesinfolder";
import Group3 from "../assets/Group3.svg"
import { getStoredValue } from '../services/logics.js';

import { LuLoader } from "react-icons/lu";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
const Files = () => {
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [folders, setFolders] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;
    const [showcreateModal, setShowcreateModal] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [showFolderContent, setShowFolderContent] = useState(false);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, folderIndex: null });
    const [newFolderName, setNewFolderName] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    // const projectId = useSelector((state) => state.project?.activeProjectId);
    const projectId = getStoredValue("activeProjectId");


    // const [loading, setloading] = useState(false);
    const toggleDropdown = (e) => {
        e.stopPropagation(); // Prevent the click from propagating to the document
        setShowDropdown((prev) => !prev);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    useEffect(() => {
        const handleDocumentClick = () => {
            closeDropdown();
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        fetchFolders();
    }, []);

    // Fetch folders from the server
    const fetchFolders = async () => {

        try {
            setLoading(true);
            const response = await axiosInstance.get(`${API_URL}/v1/folders/${projectId}`);
            console.log("API Response:", response.data);// Replace with your API base URL if needed
            setFolders(response.data);
            // setloading(true);
        } catch (error) {
            console.error("Error fetching folders:", error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }

    };

    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            alert("Folder name cannot be empty.");
            return;
        }
        try {
            const response = await axiosInstance.post(`${API_URL}/v1/folders/${projectId}/create`, { name: newFolderName, parent_folder_id: null });
            setFolders([...folders, response.data]);
            setShowCreateModal(false);
            setNewFolderName("");
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    };



    const handleDeleteFolder = async (folderId) => {
        try {
            // Send DELETE request to the API
            await axiosInstance.delete(`${API_URL}/v1/folders/${folderId}`);
            // Update the folders state by filtering out the deleted folder
            setFolders(folders.filter((folder) => folder.id !== folderId));
        } catch (error) {
            console.error("Error deleting folder:", error);
        }
        // Hide the context menu if it's open
        setContextMenu({ ...contextMenu, visible: false });
    };
    const handleOpenFolder = (folder) => {
        if (!folder) {
            console.error("Folder data is undefined:", folder);
            return;
        }
        setCurrentFolder(folder);
        setShowFolderContent(true);
    };

    // Close folder and go back to folder list
    const handleCloseFolder = () => {
        setCurrentFolder(null);
        setShowFolderContent(false);
    };
    console.log(currentFolder?.id);
    if (loading) {
        // Show a loader while data is being fetched
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-gray-500 text-xl">Loading...</div>
            </div>
        );
    }

    return (
        <div
            className={`flex absolute inset-0  bg-[#EFEEEA]  flex-col  ${isSidebarOpen ? 'ml-[280px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
        >

            <div className="p-4">

                {showFolderContent && currentFolder ? (
                    <div>



                        <FolderDocumentsPage folderId={currentFolder?.id} rootfoldername={currentFolder?.name} handleCloseFolder={handleCloseFolder} />
                    </div>
                ) : (

                    <div>
                        <div className="flex items-center justify-between">
                            {/* Text aligned to the left */}
                            <h5 className="text-2xl font-semibold py-4 px-8">Folder</h5>

                            {/* Button aligned to the right */}
                            <button
                                className="bg-black text-white py-2 px-4 rounded flex items-center"
                                onClick={toggleDropdown}
                            >
                                <FaPlus className="mr-2" /> {/* Adds space between the icon and text */}
                                New
                            </button>

                            {showDropdown && (


                                <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10" onClick={(e) => e.stopPropagation()}>
                                    <button
                                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                                        onClick={() => {
                                            setShowDropdown(false);
                                            setShowCreateModal(true);
                                        }}
                                    >
                                        Create Folder
                                    </button>
                                    <button
                                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                                        onClick={() => {
                                            setShowDropdown(false);
                                            setShowUploadModal(true);
                                        }}
                                    >
                                        Upload File
                                    </button>
                                </div>

                            )}
                        </div>
                        <div className="grid grid-cols-6 gap-4">
                            {folders.map((folder) => (
                                <div
                                    key={folder.id}
                                    className="flex flex-col items-center cursor-pointer group relative"
                                >
                                    {/* Folder Container */}
                                    <div
                                        className="flex flex-col items-center relative"
                                        onClick={() => handleOpenFolder(folder)}
                                    >
                                        {/* Folder Icon and Delete Button Container */}
                                        <div className="flex items-center justify-center relative">
                                            <img
                                                src={Group3}
                                                size={50}
                                                className="text-yellow-500"
                                                alt="Folder Icon"
                                            />
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the folder open action
                                                    handleDeleteFolder(folder.id);
                                                }}
                                                className="bg-white mb-14 text-gray rounded-full p-1 text-xs transition-opacity duration-300 ml-[-20px] opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                                            >
                                                ✖
                                            </button>
                                        </div>

                                        {/* Folder Name */}
                                        <p className="text-sm mt-2 text-center">{folder.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>





                        <div>

                            <DocumentsPage modal={showUploadModal}
                                setModal={setShowUploadModal} />
                        </div>
                    </div>

                )}


                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Folder Name</h3>
                            <input
                                type="text"
                                value={newFolderName}
                                onChange={(e) => setNewFolderName(e.target.value)}
                                placeholder="Enter folder name"
                                className="w-full px-4 py-2 border rounded mb-4 focus:outline-none focus:ring-2 border-current"
                            />
                            <div className="flex justify-end">
                                <button
                                    className="bg-white text-black px-4 py-2 rounded mr-2  border-2 border-current"
                                    onClick={() => setShowCreateModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-black text-white px-4 py-2 rounded"
                                    onClick={handleCreateFolder}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

export default Files;
