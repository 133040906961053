// import React, { useState, useEffect } from "react";
// import AddFileIcon from "../assets/documentPageAssets/AddfileIcon.svg";
// import AddFileIconGreen from "../assets/documentPageAssets/AddFileIconGreen.svg";
// import AssignmentIcon from "../assets/documentPageAssets/AssignmentIcon.svg";
// import { IoDocumentTextOutline } from "react-icons/io5";
// import CheckCircle from "../assets/documentPageAssets/CheckCircle.svg";
// import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
// import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
// import axios from "axios";
// import DocumentListShimmer from "./Documentskeleton.jsx";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { TbAlertTriangle } from "react-icons/tb";
// import Group3 from "../assets/Group3.svg"
// import FeaturedIcon from "../assets/FeaturedIcon.svg";
// import PopupModal from "../components/PopupModal";
// import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
// import SucessModal from "./SuccessModal.jsx";
// import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
// import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
// import DraftsPagination from "./Documentpagination.jsx";
// import axiosInstance from "./axiosInstance.js";
// import { useDispatch, useSelector } from 'react-redux';
// import { setDocumentsTrue, setDocumentsFalse } from '../redux/notificationSlice.js';
// import Emptydocuments from '../assets/documentPageAssets/Emptydocuments.svg'
// import Upload from '../assets/Upload.svg'
// import { FaFolder, FaPlus, FaFile } from "react-icons/fa";
// import { getStoredValue } from '../services/logics.js';

// const statusSvgGenerator = (color) => {
//     const svgString = `
//     <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
//       <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
//     </svg>
//   `;
//     const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
//     const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

//     return dataURI;
// };
// const formatCustomDateTime = (dateString, fullYear = true) => {
//     const date = new Date(dateString);

//     const day = date.getUTCDate();
//     const month = date.toLocaleString('en-US', {
//         month: 'long',
//         timeZone: 'UTC'
//     });
//     const year = fullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
//     const time = date.toLocaleTimeString('en-US', {
//         hour: 'numeric',
//         minute: 'numeric',
//         hour12: true,
//         timeZone: 'UTC'
//     }).toLowerCase();

//     if (fullYear) {
//         return `${month} ${day}, ${year}`;
//     } else {
//         return `${day} ${month} '${year}, ${time}`;
//     }
// };

// const FolderDocumentsPage = ({ folderId }) => {
//     const [file, setFile] = useState(null);
//     const [isDragOver, setIsDragOver] = useState(false);
//     // const projectId = useSelector((state) => state.project?.activeProjectId);
//     const [isUploading, setIsUploading] = useState(false);
//     const [errorMessage, setErrorMessage] = useState(''); // State for error message
//     const token = localStorage.getItem("access-token");
//     const API_URL = process.env.REACT_APP_API_URL;
//     const [isAscending, setIsAscending] = useState(false);
//     const [isFetching, setIsFetching] = useState(false);
//     const [uploadedFiles, setUploadedFiles] = useState([]);
//     const [pageNum, setPageNum] = useState(1);
//     const navigate = useNavigate();
//     const ownerName = localStorage.getItem("firstname");
//     const [totalFiles, setTotalFiles] = useState(0);
//     const [activeDropdown, setActiveDropdown] = useState(null);
//     const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
//     const [showDeleteModal, setShowDeleteModal] = useState(false);
//     const [isDeleting, setIsDeleting] = useState(false);
//     const [selectedFileId, setSelectedFileId] = useState(null); // State for storing selected file ID
//     const [showDownloadModal, setShowDownloadModal] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [showUploadModal, setShowUploadModal] = useState(false);
//     const [showCreateModal, setShowCreateModal] = useState(false);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [newFolderName, setNewFolderName] = useState('');
//     const [folders, setFolders] = useState([]);
//     const [currentFolder, setCurrentFolder] = useState(null);
//     const [showFolderContent, setShowFolderContent] = useState(false);
//     const [folderStack, setFolderStack] = useState([{ id: folderId, name: "Root" }]); // Stack to track folder hierarchy
//     const [currentFolderId, setCurrentFolderId] = useState(folderId);

//     const dispatch = useDispatch();
//     const projectId = getStoredValue("activeProjectId");

//     const role = localStorage.getItem("role");
//     useEffect(() => {
//         fetchData();
//     }, [pageNum]);

//     const fetchData = async () => {
//         setIsFetching(true);
//         try {
//             const response = await axiosInstance.get(`${API_URL}/v1/folders/${projectId}/documents/${folderId}`, {

//             });

//             const data = response.data.documents;
//             console.log(data);
//             const foldersr = response.data.subfolders;
//             const filteredData = data.map(file => ({
//                 id: file.id,
//                 uploaded_on: file.uploaded_on,
//                 name: file.name,
//                 status: file.status,
//                 url: file.url,
//                 size: (file.size / 1024).toFixed(2) + " KB",
//             }));
//             setTotalFiles(data.total);
//             handleToggleNotification(data.total);
//             setUploadedFiles(filteredData);
//             setFolders(foldersr);
//             setIsFetching(false);
//         } catch (error) {
//             toast.error(error.response?.data?.detail);
//             setIsFetching(false);
//         }
//     };

//     const handleToggleNotification = (totalFiles) => {
//         if (totalFiles > 0) {
//             dispatch(setDocumentsFalse());
//         } else if (totalFiles <= 0) {
//             dispatch(setDocumentsTrue());
//         }
//     };

//     const handleCloseModal = () => {
//         setFile(null);
//     };
//     const handleCloseDownloadModal = () => {
//         setShowDownloadModal(false)
//     }
//     const handleDelete = () => {
//         setShowDeleteModal(false);
//     };
//     const handleDragOver = (e) => {
//         e.preventDefault();
//         setIsDragOver(true);
//     };

//     const handleDragLeave = (e) => {
//         e.preventDefault();
//         setIsDragOver(false);
//     };
//     const handleOpenFolder = (folder) => {
//         if (!folder) {
//             console.error("Folder data is undefined:", folder);
//             return;
//         }
//         // Push the current folder onto the stack and update the current folder ID
//         setFolderStack([...folderStack, { id: folder.id, name: folder.name }]);
//         setCurrentFolderId(folder.id);
//     };
//     const handleGoBack = () => {
//         if (folderStack.length > 1) {
//             const newStack = [...folderStack];
//             newStack.pop(); // Remove the current folder from the stack
//             const previousFolder = newStack[newStack.length - 1];
//             setFolderStack(newStack);
//             setCurrentFolderId(previousFolder.id);
//         }
//     };

//     const handleDrop = (e, type) => {

//         e.preventDefault();
//         setIsDragOver(false);
//         let selectedFile;
//         if (type === "drop") {
//             selectedFile = e.dataTransfer.files[0];
//         } else {
//             selectedFile = e.target.files[0];
//         }

//         // Validate file format
//         if (selectedFile && selectedFile.type !== 'application/pdf') {
//             setErrorMessage('*Only .pdf files are allowed.');
//             setFile(null);
//             return;
//         } else {
//             // If valid, proceed with the upload
//             setFile(selectedFile);
//             setErrorMessage(''); // Clear any existing error message

//             // Start the upload
//             startUpload(selectedFile);
//         }

//     };
//     const handleCreateFolder = async () => {
//         if (!newFolderName.trim()) {
//             alert("Folder name cannot be empty.");
//             return;
//         }
//         try {
//             const response = await axiosInstance.post(`${API_URL}/v1/folders/${projectId}/create`, { name: newFolderName, parent_folder_id: folderId });
//             setFolders([...folders, response.data]);
//             setShowCreateModal(false);
//             setNewFolderName("");
//         } catch (error) {
//             console.error("Error creating folder:", error);
//         }
//     };

//     function convertDateReverse(dateString) {
//         const originalFormat = formatCustomDateTime(dateString);
//         const [month, day, year] = originalFormat.replace(',', '').split(' ');
//         return `${day} ${month} ${year}`; // Reverse the day and month without commas
//     }

//     const startUpload = async (uploadedFile) => {

//         const maxSizeInBytes = 10 * 1024 * 1024;

//         if (uploadedFile.size > maxSizeInBytes) {

//             setFile(null);
//             toast.error('File size exceeds the limit of 10 MB.');
//             return;
//         }

//         if (!uploadedFile) return;

//         const formData = new FormData();
//         formData.append("file", uploadedFile);
//         formData.append("folder_id", folderId);

//         try {
//             setIsUploading(true);
//             const response = await axiosInstance.post(
//                 `${API_URL}/v1/user/documents/${projectId}`,
//                 formData,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             );

//             const data = response.data;

//             const dateFetched = convertDateReverse(data.uploaded_on);
//             setUploadedFiles([
//                 ...uploadedFiles,
//                 {
//                     id: data.id,
//                     name: data.name,
//                     status: "Processing",
//                     uploaded_on: data.uploaded_on,
//                     url: data.url
//                 },
//             ]);
//             setTotalFiles(totalFiles + 1);
//             handleToggleNotification(totalFiles + 1);
//             setIsUploading(false);
//             setShowUploadModal(false);
//         } catch (error) {
//             if (error.response && error.response.status) {
//                 toast.error(error.response?.data?.detail);
//             }
//             setIsUploading(false);

//         }

//     };

//     const handlePageChange = (page) => {
//         setPageNum(page);
//     }

//     const handleDeleteDocument = async () => {
//         setIsDeleting(true);
//         try {
//             const res = await axiosInstance.delete(`${API_URL}/v1/user/documents/${selectedFileId}`);
//             const data = res.data;
//             toast.success('Deleted Successfully');
//             // Fetch the updated list of files after deletion
//             const updatedFiles = uploadedFiles.filter(file => file.id !== selectedFileId);
//             setUploadedFiles(updatedFiles);

//             // If the current page becomes empty after deletion, move to the previous page
//             if (updatedFiles.length === 0 && pageNum > 1) {
//                 setPageNum(prevPage => prevPage - 1);
//             } else {
//                 fetchData(); // If there are still documents left, simply refresh the current page
//             }
//             setShowDeleteModal(false);
//             setIsDeleting(false);
//         } catch (error) {
//             toast.error(`${error.response?.data?.detail}`);
//             setIsDeleting(false);
//         }

//     };
//     const handleDownloadClick = (doc) => {
//         setSelectedFile(doc); // Store the file details
//         setShowDownloadModal(true); // Show the modal
//     };

//     // Function to initiate the download inside the modal
//     const handleConfirmDownload = () => {
//         if (selectedFile) {
//             const link = document.createElement('a');
//             link.href = selectedFile.url;
//             link.download = selectedFile.name;
//             link.click();
//         }
//         setShowDownloadModal(false);
//     };
//     const toggleDropdown = (e) => {
//         e.stopPropagation(); // Prevent the click from propagating to the document
//         setShowDropdown((prev) => !prev);
//     };

//     const renderBreadcrumbs = () => {
//         return (
//             <div className="flex items-center space-x-2 mb-4">
//                 {folderStack.map((folder, index) => (
//                     <span
//                         key={folder.id}
//                         className={`cursor-pointer ${index < folderStack.length - 1 ? "text-blue-500" : "text-gray-700"
//                             }`}
//                         onClick={() => {
//                             setFolderStack(folderStack.slice(0, index + 1));
//                             setCurrentFolderId(folder.id);
//                         }}
//                     >
//                         {folder.name}
//                     </span>
//                 ))}
//             </div>
//         );
//     };

//     return (
//         <div
//             className={` flex flex-col items-center transition-all duration-300`}>
//             {/* Breadcrumbs */}
//             {renderBreadcrumbs()}

//             {/* Back Button */}
//             {folderStack.length > 1 && (
//                 <button
//                     className="bg-gray-300 text-gray-700 px-4 py-2 rounded mb-4"
//                     onClick={handleGoBack}
//                 >
//                     Back
//                 </button>
//             )}
//             <button
//                 className=" absolute top-4 right-4  bg-black text-white py-2 px-4 rounded flex items-center"
//                 onClick={toggleDropdown}
//             >
//                 <FaPlus className="mr-2" /> {/* Adds space between the icon and text */}
//                 New File
//             </button>
//             {showDropdown && (


//                 <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10" onClick={(e) => e.stopPropagation()}>
//                     <button
//                         className="w-full px-4 py-2 text-left hover:bg-gray-100"
//                         onClick={() => {
//                             setShowDropdown(false);
//                             setShowCreateModal(true);
//                         }}
//                     >
//                         Create Folder
//                     </button>
//                     <button
//                         className="w-full px-4 py-2 text-left hover:bg-gray-100"
//                         onClick={() => {
//                             setShowDropdown(false);
//                             setShowUploadModal(true);
//                         }}
//                     >
//                         Upload File
//                     </button>
//                 </div>

//             )}

//             {showCreateModal && (
//                 <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
//                     <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
//                         <h3 className="text-xl font-semibold mb-4">Folder Name</h3>
//                         <input
//                             type="text"
//                             value={newFolderName}
//                             onChange={(e) => setNewFolderName(e.target.value)}
//                             placeholder="Enter folder name"
//                             className="w-full px-4 py-2 border rounded mb-4 focus:outline-none focus:ring-2 border-current"
//                         />
//                         <div className="flex justify-end">
//                             <button
//                                 className="bg-white text-black px-4 py-2 rounded mr-2  border-2 border-current"
//                                 onClick={() => setShowCreateModal(false)}
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 className="bg-black text-white px-4 py-2 rounded"
//                                 onClick={handleCreateFolder}
//                             >
//                                 Create
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//             {showUploadModal && (
//                 <div
//                     className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center"
//                     onClick={() => setShowUploadModal(false)} // Close modal when clicking outside
//                 >
//                     <div
//                         className={`bg-[#FFFFFF] mb-8 mr-20 h-[343px] rounded-lg shadow-md p-6 border-[1px] border-primary-700 border-opacity-50 w-[688px] bg-[#868CA21A] `}
//                         onClick={(e) => e.stopPropagation()} // Prevent click propagation to the overlay

//                     >
//                         <div className="flex items-center justify-center mb-4">
//                             {/* <span
//                                 className={`border-[1px] bg-secondary-400  border-opacity-50 text-white rounded-full p-[22px] ${isUploading && "hidden"
//                                     } ${file ? "" : ""}`}
//                             >
//                                 {/* <img src={file} alt="" /> */}
//                             {/* </span> */}
//                             {isUploading && (
//                                 <img
//                                     src={BouncingGif}
//                                     alt="Uploading"
//                                     className={`${isUploading && "h-20 w-20"}`}
//                                 />
//                             )}
//                         </div>
//                         <h2
//                             className={`${file ? "mb-4" : ""
//                                 } text-xl text-secondary-400 leading-normal font-dmsans capitalize font-bold text-center`}
//                         >
//                             {isUploading
//                                 ? "Uploading Your Document"
//                                 : file
//                                     ? "Successfully Uploaded"
//                                     : ""}
//                         </h2>
//                         <p
//                             className={`${file && "hidden"
//                                 } text-secondary-400 text-opacity-50 font-dmsans text-base font-medium mb-2 text-center`}
//                         >
//                             {isUploading ? "Please wait while we upload your document" : ""}
//                         </p>
//                         {isUploading ? (
//                             <div className="flex flex-col mb-4 w-full justify-center items-center">
//                                 <img
//                                     src={LoadingBar3}
//                                     alt="Loading..."
//                                     className="w-64 h-[10px] p-0 m-0"
//                                 />
//                                 <div className="text-center text-[#868CA2] font-dmsans mt-3 text-xs">
//                                     Please wait while we upload your document
//                                 </div>
//                             </div>
//                         ) : (
//                             <div className={`flex justify-center ${file ? "mb-20" : ""}`}>
//                                 <input
//                                     type="file"
//                                     accept=".pdf"
//                                     onChange={(e) => {
//                                         handleDrop(e, "input-file");
//                                         console.log("File input triggered");
//                                     }}
//                                     className="hidden"
//                                     id="fileInput"
//                                 />
//                                 <img
//                                     src={Upload}
//                                     width={"150px"}
//                                     height={"150px"}
//                                     alt="Upload Icon"
//                                     onClick={() => document.getElementById("fileInput").click()} // Trigger file input on icon click
//                                     className="cursor-pointer"
//                                 />

//                                 {file && (
//                                     <span className="ml-4 text-base font-bold font-dmsans text-secondary-400">
//                                         {file.name}
//                                     </span>


//                                 )}

//                             </div>
//                         )}
//                         {errorMessage && (
//                             <p className="text-red-500 text-xs text-center">{errorMessage}</p>
//                         )}
//                         {!isUploading && (
//                             <div className="flex flex-col justify-center mt-10 items-center space-y-2">
//                                 <p className="text-[#000000] text-xl text-center">
//                                     Click here to upload your file or drag and drop.
//                                 </p>

//                                 <div className="flex items-center text-center">
//                                     <TbAlertTriangle className="mr-2 text-[#787878] text-opacity-50 w-[20px] h-[20px]" />
//                                     <span className="text-[#787878] text-opacity-50 text-xs">
//                                         Only PDF files are accepted, with a maximum file size of 10 MB.
//                                     </span>
//                                 </div>
//                             </div>
//                         )}



//                     </div>
//                 </div>
//             )}

//             <div className="grid grid-cols-6 gap-4">
//                 {folders.map((folder) => (
//                     <div
//                         key={folder.id}
//                         className="flex flex-col items-center cursor-pointer group relative"
//                     >
//                         {/* Folder Container */}
//                         <div
//                             className="flex flex-col items-center relative"
//                             onClick={() => handleOpenFolder(folder)}
//                         >
//                             {/* Folder Icon and Delete Button Container */}
//                             <div className="flex items-center justify-center relative">
//                                 <img
//                                     src={Group3}
//                                     size={50}
//                                     className="text-yellow-500"
//                                     alt="Folder Icon"
//                                 />
//                                 {/* <button
//                                     onClick={(e) => {
//                                         e.stopPropagation(); // Prevent the folder open action
//                                         handleDeleteFolder(folder.id);
//                                     }}
//                                     className="bg-white mb-14 text-gray rounded-full p-1 text-xs transition-opacity duration-300 ml-[-20px] opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
//                                 >
//                                     ✖
//                                 </button> */}
//                             </div>

//                             {/* Folder Name */}
//                             <p className="text-sm mt-2 text-center">{folder.name}</p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             {/* end the modal for file upload ends*/}
//             <div className="mx-auto w-full pb-[20px] relative ">
//                 {uploadedFiles?.length > 0 && <h2 className="text-[#000000] text-base font-medium  mb-4 mt-6 text-left mx-8">
//                     Uploaded Document
//                 </h2>}
//                 <div className="overflow-x-auto  mx-8 mb-2">
//                     {uploadedFiles?.length > 0 && <div className="flex items-center justify-between px-6 py-2 bg-[#EFEEEA]  h-[50px] text-[#2B2E3A] font-medium text-sm border-b border-gray-200">
//                         <div className="w-[110px] text-center text-xl">Name</div>
//                         <div className="flex-1  text-center ml-4 text-xl">Date</div>
//                         <div className="w-[186px] text-xl">Status</div>
//                         <div className="w-[90px] text-xl">Actions</div>
//                     </div>}
//                     {isFetching ? (
//                         <DocumentListShimmer items={5} />
//                     ) : (
//                         uploadedFiles.length === 0 && !isUploading ? (
//                             <div className="flex flex-col justify-center items-center mt-10">
//                                 <img
//                                     src={Emptydocuments}
//                                     alt=""
//                                 // className={`${isUploading && "h-20 w-20"}`}
//                                 />
//                                 <div className="text-[#000000] font-dmsans mt-2">Upload your first document to get started!</div>
//                             </div>
//                         ) : (uploadedFiles.map((doc, index) => (
//                             <div
//                                 key={index}
//                                 className="flex items-center justify-between px-4 py-4 bg-[#EFEEEA]  h-[60px]"
//                             >
//                                 {/* Date */}
//                                 <div className="flex items-center text-m text-[#2B2E3A] font-medium w-[150px] mr-10">
//                                     {/* <img src={AssignmentIcon} alt="file icon" className="h-5 w-5" /> */}
//                                     <span className="truncate text-center ml-8">
//                                         {doc.name}
//                                     </span>
//                                 </div>
//                                 <div className="flex flex-1 items-center justify-center gap-2 text-m font-medium text-[#2B2E3A] truncate">
//                                     {doc.uploaded_on}
//                                 </div>

//                                 {/* File Size and Action Buttons */}
//                                 <div className="flex items-center space-x-16 ml-10">
//                                     {/* File Size */}
//                                     <div className={`flex justify-start items-center ${role === 'admin' ? ' w-[106px]' : ' w-[106px]'}`}>
//                                         <img
//                                             className="h-fit"
//                                             src={statusSvgGenerator(
//                                                 `${doc.status === "Ready"
//                                                     ? "#000000"
//                                                     : doc.status === "Processing"
//                                                         ? "#FFE500"
//                                                         : "#FF2D2D"
//                                                 }`
//                                             )}
//                                             alt=""
//                                         />
//                                         <span className={`font-dmsans px-2 py-1 rounded-full  text-base`}>
//                                             {doc.status}
//                                         </span>
//                                     </div>

//                                     {/* Download Button */}


//                                     <div className={`flex items-center  ${role === 'admin' ? 'space-x-14' : 'space-x-14'}`}>
//                                         {/* Download Button */}
//                                         <button
//                                             className="text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] w-[30px] h-[30px] flex items-center justify-center"
//                                             onClick={() => handleDownloadClick(doc)}
//                                         >
//                                             <img
//                                                 src={DownloadIcon}
//                                                 alt="Download"
//                                                 className="w-[22px] h-[22px]"
//                                                 data-testid='download'
//                                             />
//                                         </button>


//                                         {/* Delete Button */}

//                                         <button className={`text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center w-[30px] h-[30px] ${role === 'admin' ? '' : ''}`} onClick={() => {
//                                             setShowDeleteModal(true); setSelectedFileId(doc.id);
//                                         }} data-testid="delete">
//                                             <DeleteIcon className="  w-[22px] h-[22px]" />
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         )))
//                     )}
//                     {isUploading && (
//                         <div>
//                             <DocumentListShimmer items={1} />
//                         </div>
//                     )}
//                 </div>
//                 {/* {uploadedFiles.length > 0 && <div className="mx-8"> {/* Adjust this container to align pagination to the left */}
//                 {/* <DraftsPagination
//                         currentPage={pageNum}
//                         totalDrafts={totalFiles}
//                         onPageChange={handlePageChange}
//                     />
//                 </div>} */}
//             </div>

//             {file && !isUploading && (
//                 <SucessModal text={'Document uploaded Successfully'} handleModal={handleCloseModal} />

//             )}

//             {/* Show delete Modal */}
//             {showDeleteModal && (
//                 <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
//                     <div className="flex flex-col justify-center items-center  h-[200px] w-[360px]">
//                         <img src={FeaturedIcon} alt="" className="mb-2" />
//                         <div className="text-[#101828] font-medium text-lg mb-2">Delete document</div>
//                         <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-4 text-center">
//                             Are you sure you want to delete this <br /> document? This action cannot be undone.
//                         </p>
//                         <div className="flex justify-center space-x-6 w-full">
//                             <button
//                                 className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
//                                 onClick={handleDelete}
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 type="button"
//                                 onClick={handleDeleteDocument}
//                                 disabled={isDeleting}
//                                 className="w-[150px] h-[39px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
//                             >
//                                 {isDeleting ? (
//                                     <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
//                                 ) : (
//                                     "Delete"
//                                 )}
//                             </button>
//                         </div>
//                     </div>
//                 </PopupModal>
//             )}

//             {/* Download Confirmation Modal */}
//             {showDownloadModal && (
//                 <PopupModal closeModal={handleCloseDownloadModal} className="bg-[#F9F9F9]">
//                     <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
//                         <div className="flex flex-col items-center space-y-7">
//                             <img
//                                 src={DownloadIcon}
//                                 alt="Download"
//                                 className="w-[44px] h-[44px]"
//                                 style={{
//                                     filter: 'invert(73%) sepia(84%) saturate(336%) hue-rotate(82deg) brightness(97%) contrast(97%)'
//                                 }}
//                             />
//                             <p className="text-lg font-medium  text-center">Do you want to download this file?</p>
//                             <div className="flex space-x-4">
//                                 <button
//                                     className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
//                                     onClick={handleCloseDownloadModal}
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button
//                                     className="px-3 w-[150px] h-[44px] rounded-md  font-dmsans bg-[#96F3C3] text-base text-[#000000] font-medium flex items-center justify-center"
//                                     onClick={handleConfirmDownload}
//                                 >
//                                     Download
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </PopupModal>

//             )}


//         </div>
//     );
// };

// export default FolderDocumentsPage;
import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance.js";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setDocumentsTrue, setDocumentsFalse } from "../redux/notificationSlice.js";
import Group3 from "../assets/Group3.svg";
import Emptydocuments from "../assets/documentPageAssets/Emptydocuments.svg";
import { FaFolder, FaPlus, FaFile } from "react-icons/fa";
import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
import Upload from '../assets/Upload.svg'
import { TbAlertTriangle } from "react-icons/tb";
import SucessModal from "./SuccessModal.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DocumentListShimmer from "./Documentskeleton.jsx";


const statusSvgGenerator = (color) => {
    const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
    </svg>
  `;
    const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
    const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

    return dataURI;
};
const formatCustomDateTime = (dateString, fullYear = true) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('en-US', {
        month: 'long',
        timeZone: 'UTC'
    });
    const year = fullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
    const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    }).toLowerCase();

    if (fullYear) {
        return `${month} ${day}, ${year}`;
    } else {
        return `${day} ${month} '${year}, ${time}`;
    }
};

const FolderDocumentsPage = ({ folderId, rootfoldername, handleCloseFolder }) => {
    const [folderStack, setFolderStack] = useState([{ id: folderId, name: rootfoldername }]); // Folder navigation stack
    const [currentFolderId, setCurrentFolderId] = useState(folderId);
    const [folders, setFolders] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_URL;
    const projectId = localStorage.getItem("activeProjectId");
    const token = localStorage.getItem("access-token");
    const role = localStorage.getItem("role");
    useEffect(() => {
        fetchData();
    }, [currentFolderId]);

    // Fetch folder content
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/folders/${projectId}/documents/${currentFolderId}`
            );

            const data = response.data.documents;
            const subfolders = response.data.subfolders;
            console.log(response);
            setUploadedFiles(
                data.map((file) => ({
                    id: file.id,
                    uploaded_on: file.uploaded_on,
                    name: file.name,
                    status: file.status,
                    url: file.url,
                    size: (file.size / 1024).toFixed(2) + " KB",
                }))
            );
            setFolders(subfolders);

            setTotalFiles(data.total);
            handleToggleNotification(data.total);
            setIsFetching(false);
        } catch (error) {
            toast.error(error.response?.data?.detail || "Failed to fetch data");
            setIsFetching(false);
        }
    };
    const toggleDropdown = (e) => {
        e.stopPropagation(); // Prevent the click from propagating to the document
        setShowDropdown((prev) => !prev);
    };
    const handleDeleteFolder = async (folderId) => {
        try {
            // Send DELETE request to the API
            await axiosInstance.delete(`${API_URL}/v1/folders/${folderId}`);
            // Update the folders state by filtering out the deleted folder
            setFolders(folders.filter((folder) => folder.id !== folderId));
        } catch (error) {
            console.error("Error deleting folder:", error);
        }
        // Hide the context menu if it's open

    };
    // Toggle notification based on document count
    const handleToggleNotification = (totalFiles) => {
        if (totalFiles > 0) {
            dispatch(setDocumentsFalse());
        } else {
            dispatch(setDocumentsTrue());
        }
    };
    const handleCloseModal = () => {
        setFile(null);
    };
    const handleDownloadClick = (doc) => {
        setSelectedFile(doc); // Store the file details
        setShowDownloadModal(true); // Show the modal
    };
    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            alert("Folder name cannot be empty.");
            return;
        }
        try {
            const response = await axiosInstance.post(`${API_URL}/v1/folders/${projectId}/create`, { name: newFolderName, parent_folder_id: currentFolderId });
            setFolders([...folders, response.data]);
            setShowCreateModal(false);
            setNewFolderName("");
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };
    const handleDrop = (e, type) => {

        e.preventDefault();
        setIsDragOver(false);
        let selectedFile;
        if (type === "drop") {
            selectedFile = e.dataTransfer.files[0];
        } else {
            selectedFile = e.target.files[0];
        }

        // Validate file format
        if (selectedFile && selectedFile.type !== 'application/pdf') {
            // setErrorMessage('*Only .pdf files are allowed.');
            setFile(null);
            return;
        } else {
            // If valid, proceed with the upload
            setFile(selectedFile);
            // Clear any existing error message

            // Start the upload
            startUpload(selectedFile);
        }

    };
    const startUpload = async (uploadedFile) => {

        const maxSizeInBytes = 10 * 1024 * 1024;

        if (uploadedFile.size > maxSizeInBytes) {

            setFile(null);
            toast.error('File size exceeds the limit of 10 MB.');
            return;
        }

        if (!uploadedFile) return;

        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("folder_id", currentFolderId);

        try {
            setIsUploading(true);
            const response = await axiosInstance.post(
                `${API_URL}/v1/user/documents/${projectId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const data = response.data;

            // const dateFetched = convertDateReverse(data.uploaded_on);
            setUploadedFiles([
                ...uploadedFiles,
                {
                    id: data.id,
                    name: data.name,
                    status: "Processing",
                    uploaded_on: data.uploaded_on,
                    url: data.url
                },
            ]);
            setTotalFiles(totalFiles + 1);
            handleToggleNotification(totalFiles + 1);
            setIsUploading(false);
            setShowUploadModal(false);
        } catch (error) {
            if (error.response && error.response.status) {
                toast.error(error.response?.data?.detail);
            }
            setIsUploading(false);

        }

    };
    // Navigate into a folder
    const handleOpenFolder = (folder) => {
        if (!folder) {
            console.error("Folder data is undefined:", folder);
            return;
        }
        setFolderStack([...folderStack, { id: folder.id, name: folder.name }]);
        setCurrentFolderId(folder.id);
    };

    // Navigate back to the parent folder
    const handleGoBack = () => {
        if (folderStack.length > 1) {
            const newStack = [...folderStack];
            newStack.pop();
            const previousFolder = newStack[newStack.length - 1];
            setFolderStack(newStack);
            setCurrentFolderId(previousFolder.id);
        }
    };

    // Render folder hierarchy as breadcrumbs
    const renderBreadcrumbs = () => {
        return (
            <div className="absolute top-6 left-6 flex items-center space-x-3 bg-gray-100 px-4 py-2 rounded-lg shadow-md">
                {/* Root Breadcrumb */}
                <span
                    className="cursor-pointer text-blue-600 font-semibold hover:underline"
                    onClick={handleCloseFolder} // Navigate back to the parent component
                >
                    Root
                </span>
                {folderStack.map((folder, index) => (
                    <span key={folder.id} className="flex items-center space-x-3">
                        {/* Separator */}
                        <span className="text-gray-400">/</span>
                        <span
                            className={`cursor-pointer ${index === folderStack.length - 1
                                ? "text-gray-800 font-bold"
                                : "text-blue-600 hover:underline"
                                }`}
                            onClick={() => {
                                if (index < folderStack.length - 1) {
                                    setFolderStack(folderStack.slice(0, index + 1)); // Trim stack to this folder
                                    setCurrentFolderId(folder.id);
                                }
                            }}
                        >
                            {folder.name}
                        </span>
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className="flex flex-col items-center">
            {/* Breadcrumbs */}
            {renderBreadcrumbs()}

            {/* Back Button */}
            {folderStack.length > 1 && (
                <button
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mb-4"
                    onClick={handleGoBack}
                >
                    Back
                </button>
            )}
            <button
                className=" absolute top-4 right-4  bg-black text-white py-2 px-4 rounded flex items-center"
                onClick={toggleDropdown}
            >
                <FaPlus className="mr-2" /> {/* Adds space between the icon and text */}
                New File
            </button>
            {showDropdown && (


                <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10" onClick={(e) => e.stopPropagation()}>
                    <button
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        onClick={() => {
                            setShowDropdown(false);
                            setShowCreateModal(true);
                        }}
                    >
                        Create Folder
                    </button>
                    <button
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        onClick={() => {
                            setShowDropdown(false);
                            setShowUploadModal(true);
                        }}
                    >
                        Upload File
                    </button>
                </div>

            )}
            {/* Folders */}
            {/* <div className="grid grid-cols-6 gap-4 mb-4">
                {folders.map((folder) => (
                    <div
                        key={folder.id}
                        className="flex flex-col items-center cursor-pointer group"
                        onClick={() => handleOpenFolder(folder)}
                    >
                        <img src={Group3} alt="Folder Icon" className="text-yellow-500" />
                        <p className="text-sm mt-2 text-center">{folder.name}</p>
                    </div>
                ))}
            </div> */}
            <div className="mb-4">
                <h4 className="font-medium mb-4">Folders</h4>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full border-collapse border border-gray-300">
                        {/* Table Header */}
                        <thead>
                            <tr className="bg-gray-100 text-left">
                                <th className="border border-gray-300 px-4 py-2">Folder Name</th>
                                <th className="border border-gray-300 px-4 py-2">Created On</th>
                                <th className="border border-gray-300 px-4 py-2">Actions</th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody>
                            {folders.map((folder) => (
                                <tr key={folder.id} className="hover:bg-gray-50">
                                    {/* Folder Name */}
                                    <td
                                        className="border border-gray-300 px-4 py-2 cursor-pointer text-blue-600 hover:underline"
                                        onClick={() => handleOpenFolder(folder)}
                                    >
                                        {folder.name}
                                    </td>

                                    {/* Created On */}
                                    <td className="border border-gray-300 px-4 py-2">
                                        {folder.created_on ? new Date(folder.created_on).toLocaleDateString() : "N/A"}
                                    </td>

                                    {/* Actions */}
                                    <td className="border border-gray-300 px-4 py-2">
                                        <button
                                            className="text-red-600 hover:text-red-800 font-medium"
                                            onClick={() => handleDeleteFolder(folder.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Documents */}
            <div className="mx-auto w-full pb-[20px] relative ">
                {uploadedFiles?.length > 0 && <h2 className="text-[#000000] text-base font-medium  mb-4 mt-6 text-left mx-8">
                    Uploaded Document
                </h2>}
                <div className="overflow-x-auto  mx-8 mb-2">
                    {uploadedFiles?.length > 0 && <div className="flex items-center justify-between px-6 py-2 bg-[#EFEEEA]  h-[50px] text-[#2B2E3A] font-medium text-sm border-b border-gray-200">
                        <div className="w-[110px] text-center text-xl">Name</div>
                        <div className="flex-1  text-center ml-4 text-xl">Date</div>
                        <div className="w-[186px] text-xl">Status</div>
                        <div className="w-[90px] text-xl">Actions</div>
                    </div>}
                    {isFetching ? (
                        <DocumentListShimmer items={5} />
                    ) : (
                        uploadedFiles.length === 0 && !isUploading ? (
                            <div className="flex flex-col justify-center items-center mt-10">
                                <img
                                    src={Emptydocuments}
                                    alt=""
                                // className={`${isUploading && "h-20 w-20"}`}
                                />
                                <div className="text-[#000000] font-dmsans mt-2">Upload your first document to get started!</div>
                            </div>
                        ) : (uploadedFiles.map((doc, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between px-4 py-4 bg-[#EFEEEA]  h-[60px]"
                            >
                                {/* Date */}
                                <div className="flex items-center text-m text-[#2B2E3A] font-medium w-[150px] mr-10">
                                    {/* <img src={AssignmentIcon} alt="file icon" className="h-5 w-5" /> */}
                                    <span className="truncate text-center ml-8">
                                        {doc.name}
                                    </span>
                                </div>
                                <div className="flex flex-1 items-center justify-center gap-2 text-m font-medium text-[#2B2E3A] truncate">
                                    {doc.uploaded_on}
                                </div>

                                {/* File Size and Action Buttons */}
                                <div className="flex items-center space-x-16 ml-10">
                                    {/* File Size */}
                                    <div className={`flex justify-start items-center ${role === 'admin' ? ' w-[106px]' : ' w-[106px]'}`}>
                                        <img
                                            className="h-fit"
                                            src={statusSvgGenerator(
                                                `${doc.status === "Ready"
                                                    ? "#000000"
                                                    : doc.status === "Processing"
                                                        ? "#FFE500"
                                                        : "#FF2D2D"
                                                }`
                                            )}
                                            alt=""
                                        />
                                        <span className={`font-dmsans px-2 py-1 rounded-full  text-base`}>
                                            {doc.status}
                                        </span>
                                    </div>

                                    {/* Download Button */}


                                    <div className={`flex items-center  ${role === 'admin' ? 'space-x-14' : 'space-x-14'}`}>
                                        {/* Download Button */}
                                        <button
                                            className="text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] w-[30px] h-[30px] flex items-center justify-center"
                                            onClick={() => handleDownloadClick(doc)}
                                        >
                                            <img
                                                src={DownloadIcon}
                                                alt="Download"
                                                className="w-[22px] h-[22px]"
                                                data-testid='download'
                                            />
                                        </button>


                                        {/* Delete Button */}

                                        <button className={`text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center w-[30px] h-[30px] ${role === 'admin' ? '' : ''}`} onClick={() => {
                                            setShowDeleteModal(true); setSelectedFileId(doc.id);
                                        }} data-testid="delete">
                                            <DeleteIcon className="  w-[22px] h-[22px]" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )))
                    )}
                    {isUploading && (
                        <div>
                            <DocumentListShimmer items={1} />
                        </div>
                    )}
                </div>
                {/* {uploadedFiles.length > 0 && <div className="mx-8"> {/* Adjust this container to align pagination to the left */}
                {/* <DraftsPagination
                                    currentPage={pageNum}
                                    totalDrafts={totalFiles}
                                    onPageChange={handlePageChange}
                                />
                            </div>} */}
            </div>

            {file && !isUploading && (
                <SucessModal text={'Document uploaded Successfully'} handleModal={handleCloseModal} />

            )}

            {showUploadModal && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center"
                    onClick={() => setShowUploadModal(false)} // Close modal when clicking outside
                >
                    <div
                        className={`bg-[#FFFFFF] mb-8 mr-20 h-[343px] rounded-lg shadow-md p-6 border-[1px] border-primary-700 border-opacity-50 w-[688px] bg-[#868CA21A] `}
                        onClick={(e) => e.stopPropagation()} // Prevent click propagation to the overlay

                    >
                        <div className="flex items-center justify-center mb-4">
                            {/* <span
                                            className={`border-[1px] bg-secondary-400  border-opacity-50 text-white rounded-full p-[22px] ${isUploading && "hidden"
                                                } ${file ? "" : ""}`}
                                        >
                                            {/* <img src={file} alt="" /> */}
                            {/* </span> */}
                            {isUploading && (
                                <img
                                    src={BouncingGif}
                                    alt="Uploading"
                                    className={`${isUploading && "h-20 w-20"}`}
                                />
                            )}
                        </div>
                        <h2
                            className={`${file ? "mb-4" : ""
                                } text-xl text-secondary-400 leading-normal font-dmsans capitalize font-bold text-center`}
                        >
                            {isUploading
                                ? "Uploading Your Document"
                                : file
                                    ? "Successfully Uploaded"
                                    : ""}
                        </h2>
                        <p
                            className={`${file && "hidden"
                                } text-secondary-400 text-opacity-50 font-dmsans text-base font-medium mb-2 text-center`}
                        >
                            {isUploading ? "Please wait while we upload your document" : ""}
                        </p>
                        {isUploading ? (
                            <div className="flex flex-col mb-4 w-full justify-center items-center">
                                <img
                                    src={LoadingBar3}
                                    alt="Loading..."
                                    className="w-64 h-[10px] p-0 m-0"
                                />
                                <div className="text-center text-[#868CA2] font-dmsans mt-3 text-xs">
                                    Please wait while we upload your document
                                </div>
                            </div>
                        ) : (
                            <div className={`flex justify-center ${file ? "mb-20" : ""}`}>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        handleDrop(e, "input-file");
                                        console.log("File input triggered");
                                    }}
                                    className="hidden"
                                    id="fileInput"
                                />
                                <img
                                    src={Upload}
                                    width={"150px"}
                                    height={"150px"}
                                    alt="Upload Icon"
                                    onClick={() => document.getElementById("fileInput").click()} // Trigger file input on icon click
                                    className="cursor-pointer"
                                />

                                {file && (
                                    <span className="ml-4 text-base font-bold font-dmsans text-secondary-400">
                                        {file.name}
                                    </span>


                                )}

                            </div>
                        )}
                        {errorMessage && (
                            <p className="text-red-500 text-xs text-center">{errorMessage}</p>
                        )}
                        {!isUploading && (
                            <div className="flex flex-col justify-center mt-10 items-center space-y-2">
                                <p className="text-[#000000] text-xl text-center">
                                    Click here to upload your file or drag and drop.
                                </p>

                                <div className="flex items-center text-center">
                                    <TbAlertTriangle className="mr-2 text-[#787878] text-opacity-50 w-[20px] h-[20px]" />
                                    <span className="text-[#787878] text-opacity-50 text-xs">
                                        Only PDF files are accepted, with a maximum file size of 10 MB.
                                    </span>
                                </div>
                            </div>
                        )}



                    </div>
                </div>
            )}


            {showCreateModal && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold mb-4">Folder Name</h3>
                        <input
                            type="text"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            placeholder="Enter folder name"
                            className="w-full px-4 py-2 border rounded mb-4 focus:outline-none focus:ring-2 border-current"
                        />
                        <div className="flex justify-end">
                            <button
                                className="bg-white text-black px-4 py-2 rounded mr-2  border-2 border-current"
                                onClick={() => setShowCreateModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-black text-white px-4 py-2 rounded"
                                onClick={handleCreateFolder}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FolderDocumentsPage;
