import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const Landing = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Navbar */}
            <nav className="bg-[#000000] shadow-lg">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex justify-between items-center">
                        <div className="flex space-x-7">
                            <div>
                                <Link to="/" className="flex items-center py-4 px-2">

                                    <span className="font-semibold text-white text-lg">Pragya</span>
                                </Link>
                            </div>
                        </div>
                        <div className="hidden md:flex items-center space-x-3">
                            <Link to="/" className="py-4 px-2 text-white font-semibold hover:text-green-500 transition duration-300">Home</Link>
                            {/* <Link to="/pricing" className="py-4 px-2 text-white font-semibold hover:text-green-500 transition duration-300">Pricing</Link>
                            <Link to="/about" className="py-4 px-2 text-white font-semibold hover:text-green-500 transition duration-300">About Us</Link> */}
                            <Link to="/signin" className="py-2 px-4 font-semibold text-white rounded border border-white hover:text-[#a505a8] hover:bg-white transition duration-300">Sign In</Link>
                            <Link to="/signup" className="py-2 px-4 font-semibold text-white  rounded hover:text-[#a505a8] hover:bg-white transition duration-300">Sign Up</Link>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="py-20" style={{ background: "#ffffff" }}>
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold mb-2 text-black">
                        Create Powerful AI Assistants
                    </h2>
                    <h3 className="text-2xl mb-8 text-black-200">
                        Empower your business with intelligent AI solutions
                    </h3>
                    <button className="bg-[#000000] shadow-lg text-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider"><Link to="/signup">
                        Get Started</Link>
                    </button>
                </div>
            </div>

            {/* Features Section */}
            <div className="container mx-auto px-6 py-20">
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    Our Features
                </h2>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <div className="bg-white rounded shadow-lg p-6">
                            <h3 className="text-2xl font-bold text-gray-800 mb-4">AI Assistants</h3>
                            <p className="text-gray-600">Create custom AI assistants tailored to your specific needs and industry.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <div className="bg-white rounded shadow-lg p-6">
                            <h3 className="text-2xl font-bold text-gray-800 mb-4">Voice AI</h3>
                            <p className="text-gray-600">Implement voice-activated AI assistants for hands-free operation and accessibility.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <div className="bg-white rounded shadow-lg p-6">
                            <h3 className="text-2xl font-bold text-gray-800 mb-4">Tool Integration</h3>
                            <p className="text-gray-600">Seamlessly integrate with tools like Google Calendar for enhanced productivity.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-white">
                <div className="container mx-auto px-6 py-20">
                    <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                        Ready to get started?
                    </h2>
                    <div className="flex justify-center">
                        <button className="bg-[#000000] text-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
                            Create Your AI Assistant
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Landing;
