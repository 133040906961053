import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Project from './Projects';
import axiosInstance from '../components/axiosInstance';
import InviteModal from './Invitemodal';
import { toast } from "react-toastify";
import Billing from '../pages/Integrations';

const MembersPage = () => {
    // const [selectedOrg, setSelectedOrg] = useState(null);
    const [organizationusers, setOrganizationusers] = useState([]);
    const [organizationinvites, setOrganizationinvites] = useState([]);

    const [activeTab, setActiveTab] = useState('General');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    // State for selected organization
    const [members, setMembers] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
    });

    useEffect(() => {
        const fetchUserData = async () => {
            // assuming token is stored in localStorage
            try {
                const response = await axiosInstance.get(`/users/me`, {

                });
                localStorage.setItem("user_id", response.data.id);

                console.log(response.status, 'nnnnnnnn')
                if (response.status !== 200) {
                    throw new Error('Failed to fetch user data');
                }

                const data = response.data;
                setUser(data);
            } catch (err) {
                toast.error(err.response.data.detail);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);
    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };
    const handleLogout = async () => {
        try {
            const response = await axiosInstance.post(`/auth/jwt/logout`);

            localStorage.clear();
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 2000);
            localStorage.setItem("isLoggedIn", "false");
            window.location.href = "/signin";
        }
        catch {

        }
    };
    const handleOrgChange = (orgName) => {
        console.log(orgName, 'ddddddddddddddddddddddddddd');
        const orgObject = organizations.find(org => org.id === orgName);
        setSelectedOrg(orgName); // Update the state with the object
        setCurrentOrg(orgObject); // Update the state with the object
        console.log(currentOrg, 'kkkkkkkkkkkk')
        console.log(`Switching to organization: ${orgName}`);
    };
    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await axiosInstance.get('/v1/organizations/user'); // Replace with your API endpoint
                console.log(response.data);
                setOrganizations(response.data);
                setCurrentOrg(response.data[0]);
                console.log(selectedOrg, 'kkkkkkkkkkkkk')
            } catch (err) {
                setError(err);
                toast.error("Error fetching organizations:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations();
    }, []);
    // const id = '8b6291df-5364-4560-aeee-0e1640afd7e1';
    useEffect(() => {
        const fetchOrganizationUsers = async () => {
            if (!currentOrg?.id) return;
            try {
                const response = await axiosInstance.get(`/v1/organizations/${currentOrg.id}/users`);
                setOrganizationusers(response.data);
                console.log(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchOrganizationUsers();
    }, [currentOrg, members]);

    useEffect(() => {
        const fetchOrganizationinvites = async () => {
            if (!currentOrg?.id) return;
            try {
                const response = await axiosInstance.get(`/v1/organizations/${currentOrg?.id}/invites`);
                setOrganizationinvites(response.data);
                console.log(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchOrganizationinvites();
    }, [currentOrg, members]);

    const leaveOrganization = async () => {
        try {
            const response = await axiosInstance.post(`/v1/organizations/${currentOrg?.id}/leave`); // Replace with your API endpoint
            console.log(response.data);
            setOrganizationusers(response.data);
        } catch (err) {
            setError(err);
            console.error("Error fetching organizations:", err);
        } finally {
            setLoading(false);
        }
    };

    const removeUser = async (user_id) => {
        try {
            // Optimistically update the UI by filtering out the user
            setOrganizationusers(prevUsers => prevUsers.filter(user => user.user_id !== user_id));

            // Make the API call to remove the user from the backend
            await axiosInstance.post(`/v1/organizations/${currentOrg?.id}/remove-user`, {
                target_user_id: user_id
            });

            toast.success(`User with ID ${user_id} removed successfully.`);
        } catch (err) {
            // Revert the optimistic update in case of an error
            setError(err.message);
            toast.error("Error removing user");
        }
    };
    const deleteinvite = async (invite_id) => {
        try {
            // Optimistically update the UI by filtering out the user
            setOrganizationinvites(prevUsers => prevUsers.filter(user => user.id !== invite_id));

            // Make the API call to remove the user from the backend
            await axiosInstance.delete(`/v1/invites/${invite_id}`);

            toast.success(`Invite is removed successfully.`);
        } catch (err) {
            // Revert the optimistic update in case of an error
            setError(err.message);
            console.error("Error deleting invite");
        }
    };

    const filteredMembers = members.filter(member =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


    const handleRoleChange = async (userId, newRole, organisation_id) => {
        try {
            // Update the role in the backend
            const response = await axiosInstance.patch(
                `/v1/organizations/${organisation_id}/update-role`, // Replace with the actual API endpoint
                {
                    target_user_id: userId,
                    role: newRole
                }
            );

            // On success, update the role in the frontend state
            if (response.status === 204) {
                setMembers(prevMembers =>
                    prevMembers.map(member =>
                        member.user_id === userId ? { ...member, role: newRole } : member
                    )
                );
                toast.success("Role updated successfully!");
            } else {
                throw new Error("Failed to update role.");
            }
        } catch (error) {
            console.error("Error updating role:", error);
            toast.error(error.response?.data?.message || "An error occurred while updating the role.");
        }
    };


    const renderContent = () => {
        if (activeTab === 'General') {


            return (
                <div className="p-6">
                    <h1 className="text-2xl font-semibold">{currentOrg?.id}</h1>
                    <h1 className="text-2xl font-semibold">{currentOrg?.name}</h1>
                    <p>Email: {currentOrg?.email}</p>
                    <p>Address: {currentOrg?.address}</p>
                    <p>Phone: {currentOrg?.phone_no}</p>
                </div>
            );
        }
        else if (activeTab === "Members") {
            const currentUser = organizationusers.find(
                (member) => member.email === user.email
            );
            if (currentUser?.role === 'MEMBER') {
                return (
                    <div className="m-10 text-center">
                        <p className="text-lg font-semibold text-red-500">
                            You do not have permission to edit these settings.
                        </p>
                        <p className="text-gray-600">
                            Only members of your organization with the "Owner" role can edit these settings.
                        </p>
                    </div>
                );
            }

            return (
                <div className='m-10'>
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-2xl font-semibold">Members</h1>
                        <div className="flex items-center">
                            {/* <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2" >+ Service account</button> */}
                            <button className="bg-black  text-white font-bold py-2 px-4 rounded" onClick={() => setIsModalOpen(true)}>+ Invite</button>
                            <InviteModal organization_id={currentOrg?.id} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                        </div>
                    </div>

                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="w-1/2 px-4 py-2 border rounded mb-4"
                    />

                    <div className="bg-white rounded shadow">
                        {organizationusers && organizationusers?.map(member => (
                            <div key={member.email} className="flex items-center p-4 border-b last:border-b-0">
                                <div className="rounded-full bg-gray-300 w-10 h-10 flex items-center justify-center mr-4">
                                    {member.full_name.charAt(0).toUpperCase()}
                                </div>
                                <div className="flex-1">
                                    <p className="font-medium">{member.full_name} {member.email === user.email && <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">You</span>}</p>
                                    <p className="text-gray-500 text-sm">{member.email}</p>
                                </div>
                                <div className="flex-shrink-0" style={{ width: '150px' }}>
                                    <select
                                        value={member?.role}
                                        onChange={(e) => handleRoleChange(member.user_id, e.target.value, member.organization_id)}
                                        className="border rounded px-2 py-1"
                                    >
                                        <option value="OWNER">Owner</option>
                                        <option value="MEMBER">Member</option>
                                    </select>
                                </div>
                                <div className="flex-shrink-0 ml-4" style={{ width: '100px' }}>
                                    {(member.role === 'OWNER' && member.email === user.email) ? (
                                        <></>
                                        // <button className="text-gray-500" onClick={() => leaveOrganization()}>Leave</button>
                                    ) : (
                                        <button className="text-red-500" onClick={() => removeUser(member.user_id)}>Remove</button>
                                    )}
                                </div>
                            </div>
                        ))}
                        {!organizationusers && <p>Loading members...</p>}
                        {organizationinvites && organizationinvites?.map(member => (
                            <div key={member.email} className="flex items-center p-4 border-b last:border-b-0">
                                <div className="rounded-full bg-gray-300 w-10 h-10 flex items-center justify-center mr-4">
                                    {member.email.charAt(0).toUpperCase()}
                                </div>
                                <div className="flex-1">
                                    <p className="text-gray-500 text-sm">{member.email}</p>
                                </div>
                                <div className="flex-shrink-0" style={{ width: '150px' }}>
                                    <p className="text-gray-500 text-sm">
                                        {member.status === 'Sent' ? 'Invite is pending' : member.status === 'Expired' ? 'Invite expired' : member.status}
                                    </p>
                                </div>
                                <div className="flex-shrink-0 ml-4" style={{ width: '100px' }}>

                                    <button className="text-red-500" onClick={() => deleteinvite(member.id)}>Remove</button>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        else if (activeTab === "Projects") {
            const currentUser = organizationusers.find(
                (member) => member.email === user.email
            );
            return (
                <div>
                    <Project organization_id={currentOrg?.id} userorganization_id={currentUser?.id} role={currentUser?.role} />
                </div>
            )
        }
        else if (activeTab === "Billing") {
            return (
                <div>
                    <Billing />
                </div>
            )
        }
        else {
            return (
                <div className="p-6">
                    <h1 className="text-2xl font-semibold">{activeTab}</h1>
                    {/* Content for other tabs */}
                    <p>Content for {activeTab} will go here.</p>

                </div>
            )
        }
    }

    return (
        <div className="flex flex-col h-screen bg-gray-100"> {/* Changed to flex column */}
            <nav className="bg-white p-4 shadow-md w-full"> {/* Removed container and added w-full */}
                <div className="flex justify-between items-center"> {/* Removed container mx-auto */}
                    <div className="flex items-center">
                        {/* Organization Dropdown */}
                        <div className="mr-6">
                            <select
                                value={selectedOrg}
                                onChange={(e) => handleOrgChange(e.target.value)}
                                className="border rounded px-2 py-1"
                            >
                                {organizations.map((org) => (
                                    <option key={org.id} value={org.id}>
                                        {org.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* <img src="/your-logo.png" alt="Logo" className="h-8 mr-2" /> */}
                        <span className="font-bold text-xl">Pragya</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button className="text-gray-600 hover:text-gray-900">Notifications</button>
                        <div className="relative inline-block text-left"> {/* User Icon/Dropdown */}
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300 text-lg font-medium text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={toggleUserDropdown}
                                >
                                    {user.email.charAt(0).toUpperCase()} {/* User Icon (First Letter) */}
                                </button>

                                {/* Dropdown menu */}
                                {isUserDropdownOpen && (
                                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                        <div className="py-1" role="none">
                                            <span className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                {user.email} {/* Display Full User Name */}
                                            </span>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-1">
                                                Your Profile
                                            </a>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-2" onClick={handleLogout}>
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>


            <div className="flex flex-1">
                <Sidebar activeTab={activeTab} handleTabClick={handleTabClick} />

                {/* Main Content */}
                <div className="flex-1">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default MembersPage;