import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleSignInButton from '../components/GoogleSigninbutton';
import axiosInstance from '../components/axiosInstance';
import logo from '../assets/logo.svg';
import { toast } from "react-toastify";
const SignIn = () => {
    const [formData, setFormData] = useState({
        username: '', // Ensure this matches the form field
        password: '',
    });
    const frontend_URL = process.env.Frontend_url;
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    // Handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData();
        data.append('username', formData.username);
        data.append('password', formData.password);

        try {
            // Use axiosInstance for the login request
            const response = await axiosInstance.post('/auth/jwt/login', data, {
                withCredentials: true,
                headers: {
                    "Access-Control-Allow-Origin": `${frontend_URL}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Extract access token from the response
            const { access_token } = response.data;
            const has_org = response.data.has_org;
            console.log(response);

            // Save the access token and login state in localStorage
            localStorage.setItem('access_token', access_token);
            localStorage.setItem("isLoggedIn", "true");

            console.log('Access token saved, attempting to navigate...');

            // Delay navigation slightly for smoother user experience
            if (has_org === true) {
                setTimeout(() => {
                    navigate('/organizations', { state: { email: formData.username } });
                }, 100);
            }
            else {
                setTimeout(() => {
                    navigate('/createorganization', { state: { email: formData.username } });
                }, 100);
            }

        } catch (error) {
            console.error('Error:', error);


            if (error.status !== 399) {
                navigate('/signup');
            }
            else {
                toast.error("Email not verified");
            }
        }
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-black">
            {/* Background image with blur effect */}
            <div className="absolute inset-0 bg-cover bg-center filter bg-black">
                <div className="absolute top-0 w-full flex flex-col items-center mt-10">
                    <img
                        src={logo}
                        alt="Success Tick"
                        className="h-[45px] w-[45px]"
                    />
                    <h2 className="text-xl font-semibold text-white mt-2">Welcome to Pragya.ai</h2>
                </div>

            </div>
            <div className="relative z-10 w-full max-w-md p-8 bg-[#1A1B1EFF]  rounded-lg shadow-lg">
                <h1 className="text-2xl  mb-6 text-center text-white font-bold">Sign In</h1>
                <form className="space-y-6" onSubmit={handleFormSubmit}>
                    <div>
                        <label htmlFor="username" className="block text-l  text-white font-bold mb-2">Username</label>
                        <input
                            id="username"
                            type="text"
                            placeholder="Enter your username"
                            name="username" // Ensure this matches the formData structure
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                            className="w-full p-3 border border-gray-300 rounded-lg bg-black text-white focus:outline-white focus:ring-2 "
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-l  text-white font-bold mb-2">Password</label>
                        <input
                            id="password"
                            type="password"
                            placeholder="Enter your password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                            className="w-full p-3 border border-gray-300 rounded-lg  bg-black text-white focus:outline-white focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-[#EFEEEA]  text-black font-bold rounded-lg focus:outline-none focus:ring-2"
                    >
                        Sign In
                    </button>
                </form>
                <GoogleSignInButton />
                <p className="text-center mt-4 text-green-50">
                    Not signed up? <a href="/signup" className='text-green-300 font-bold'>Sign up here</a>
                </p>
                <p className="text-center mt-4 text-green-50">
                    Forgotten Password? <a href="/forgot" className='text-green-300 font-bold'>Forgot Password</a>
                </p>
            </div>
        </div>
    );
};

export default SignIn;
