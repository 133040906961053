import React from "react";

const TermsOfService = () => {
    return (
        <div className="bg-gray-50 min-h-screen py-8 px-4 sm:px-8 lg:px-16">
            <div className="max-w-5xl mx-auto bg-white shadow-sm rounded-lg p-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
                    Terms of Service
                </h1>
                <div className="space-y-6 text-gray-700">
                    <p>
                        These Terms of Service ("Terms") govern your access to and use of
                        Pragya, its associated services, and software (collectively referred
                        to as the "Services"). By accessing or using the Services, you agree
                        to these Terms. If you are using the Services on behalf of an
                        organization, you affirm that you possess the authority to bind that
                        organization to these Terms, in which case "you" and "your" shall
                        refer to the organization. Both Pragya and you shall be referred to
                        as a "Party" to these Terms.
                    </p>
                    <p>
                        Pragya reserves the right to modify these Terms at any time. Updates
                        will be posted on the platform, and your continued use of the
                        Services following such modifications constitutes your acceptance of
                        the revised Terms.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        1. Use of Pragya – User Commitments
                    </h2>
                    <p>
                        To ensure proper functioning of the Services, and in exchange for
                        access to such services, you agree to the following commitments when
                        accessing and using our platform:
                    </p>
                    <h3 className="font-semibold">Eligibility</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            You must be a natural person. The use of the platform by automated
                            systems or bots, unless authorized by Pragya, is prohibited.
                        </li>
                        <li>
                            You agree to these Terms and the Privacy Policy either as an
                            individual or on behalf of your organization.
                        </li>
                    </ul>
                    <h3 className="font-semibold">Account Security</h3>
                    <ul className="list-disc ml-6">
                        <li>
                            If you share your login credentials or access codes with a third
                            party, you will be fully responsible for any actions taken by that
                            third party on your behalf.
                        </li>
                        <li>
                            Pragya disclaims any liability for such actions, which will be
                            deemed as actions taken by you.
                        </li>
                    </ul>
                    <h3 className="font-semibold">User Contributions</h3>
                    <p>
                        Should you provide questions, comments, suggestions, enhancements,
                        ideas, or other feedback regarding the platform, you grant Pragya
                        the right to use and disclose such feedback without restriction and
                        without compensation.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        2. Acceptable Use
                    </h2>
                    <p>When using Pragya’s Services, you agree not to:</p>
                    <ul className="list-disc ml-6">
                        <li>Provide system passwords or other login information to any unauthorized third party.</li>
                        <li>Share nonpublic system features or content with any third party.</li>
                        <li>
                            Access the system for the purpose of developing a competitive
                            product or service or to create a product utilizing similar ideas,
                            features, functions, or graphics of the system.
                        </li>
                        <li>
                            Engage in web scraping, data scraping, or any other form of data
                            collection that violates these Terms.
                        </li>
                        <li>
                            Use Pragya’s AI-driven tools or language models (LLMs) for
                            generating or disseminating harmful, illegal, or unethical content.
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        3. Links to Third-Party Services
                    </h2>
                    <p>
                        Our Services may contain links to third-party websites or services
                        that are not owned or controlled by Pragya. Pragya has no control
                        over, and assumes no responsibility for, the content, privacy
                        policies, or practices of any third-party websites or services.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        4. Subscriptions and Payments
                    </h2>
                    <p>
                        Certain components of the Service are billed on a subscription basis
                        ("Subscription(s)"). Billing Cycles are established on either a
                        monthly or annual basis, depending on the type of subscription plan
                        you select at the time of purchase.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        5. Data Ownership and Privacy
                    </h2>
                    <p>
                        "Your Data" refers to any data and content stored or transmitted via
                        the Services by or on behalf of you or your end users, which may
                        include data imported from third-party services. You retain full
                        ownership of Your Data.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        6. Intellectual Property
                    </h2>
                    <p>
                        Pragya retains ownership of all intellectual property rights
                        associated with the Services, including trademarks, software,
                        designs, and documentation.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        7. Limitation of Liability
                    </h2>
                    <p>
                        To the fullest extent permitted by law, Pragya shall not be liable
                        for any indirect, incidental, or consequential damages arising from
                        your use of the Services.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        8. Modifications and Termination
                    </h2>
                    <p>
                        Pragya reserves the right to modify, suspend, or discontinue any
                        part of the Services at its sole discretion.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        9. Governing Law
                    </h2>
                    <p>
                        These Terms shall be governed by and construed in accordance with
                        the laws of [Your Jurisdiction], without regard to its conflict of
                        law principles.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-900 mt-6">
                        10. Contact Information
                    </h2>
                    <p>
                        For questions, concerns, or feedback regarding these Terms, please
                        contact Pragya at [support email/contact details].
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
