import axios from "axios";
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

export const googleLogin = async (code, state) => {

    try {
        const res = await axios.get(
            `${API_URL}/auth/google/callback?code=${code}&state=${state}&access_type=offline&prompt=consent&include_granted_scopes=true`,
            {
                withCredentials: true,
            }
        );
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("isLoggedIn", "true");

        const response = await axiosInstance.get(
            '/auth/refresh-token'

        );
        const has_org = response.data.has_org;

        return has_org;
    } catch (error) {
        console.error("Error during Google login:", error);
        throw error;
    }
};
