import React from 'react';
import Sidebar from '../components/Sidebar'; // Import your Sidebar component
import { Outlet } from 'react-router-dom';  // Outlet is used to render the child route
import { setActiveProjectId } from '../redux/projectSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Layout = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    console.log(projectId);
    useEffect(() => {
        dispatch(setActiveProjectId(projectId));
    }, [dispatch, projectId]);
    console.log()
    return (
        <div className="flex">
            {/* Sidebar */}
            <Sidebar />

            {/* Main content */}
            <div className="flex-grow p-4 bg-white h-screen">
                {/* Outlet renders the current page */}
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
