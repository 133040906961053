import React, { useState, useEffect } from "react";
import AddFileIcon from "../assets/documentPageAssets/AddfileIcon.svg";
import AddFileIconGreen from "../assets/documentPageAssets/AddFileIconGreen.svg";
import AssignmentIcon from "../assets/documentPageAssets/AssignmentIcon.svg";
// import { formatCustomDateTime } from "../services/logics";
import { FaFolder, FaPlus, FaFile } from "react-icons/fa";

import CheckCircle from "../assets/documentPageAssets/CheckCircle.svg";
import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
import axios from "axios";
import DocumentListShimmer from "./Documentskeleton.jsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TbAlertTriangle } from "react-icons/tb";
import { useSelector } from 'react-redux';
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import SucessModal from "./SuccessModal.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DraftsPagination from "./Documentpagination.jsx";
import axiosInstance from "./axiosInstance.js";
import { useDispatch } from 'react-redux';
import { setDocumentsTrue, setDocumentsFalse } from '../redux/notificationSlice.js';
import Emptydocuments from '../assets/documentPageAssets/Emptydocuments.svg'
import Upload from '../assets/Upload.svg'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { getStoredValue } from '../services/logics.js';

const statusSvgGenerator = (color) => {
    const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
    </svg>
  `;
    const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
    const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

    return dataURI;
};
const formatCustomDateTime = (dateString, fullYear = true) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('en-US', {
        month: 'long',
        timeZone: 'UTC'
    });
    const year = fullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
    const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    }).toLowerCase();

    if (fullYear) {
        return `${month} ${day}, ${year}`;
    } else {
        return `${day} ${month} '${year}, ${time}`;
    }
};

const DocumentsPage = ({ modal, setModal }) => {
    // const projectId = useSelector((state) => state.project?.activeProjectId);
    const [file, setFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    // const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const token = localStorage.getItem("access-token");
    const API_URL = process.env.REACT_APP_API_URL;
    const [isAscending, setIsAscending] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const navigate = useNavigate();
    const ownerName = localStorage.getItem("firstname");
    const [totalFiles, setTotalFiles] = useState(0);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null); // State for storing selected file ID
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showMoveCheckbox, setShowMoveCheckbox] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files
    const [showMoveModal, setShowMoveModal] = useState(false); // Modal visibility
    const [folders, setFolders] = useState([]); // Fetched folders
    const [selectedFolder, setSelectedFolder] = useState(null); // Selected folder
    const [fileurl, setFileurl] = useState(null);
    const dispatch = useDispatch();
    const projectId = getStoredValue("activeProjectId");

    const role = localStorage.getItem("role");
    useEffect(() => {
        fetchData();
    }, [pageNum]);


    useEffect(() => {
        if (showMoveModal) {
            fetchFolders();
        }
    }, [showMoveModal]);
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/user/documents/${projectId}?page=${pageNum}&page_size=5`, {

            });

            const data = response.data;

            const filteredData = data.documents.map(file => ({
                id: file.id,
                uploaded_on: file.uploaded_on,
                name: file.name,
                status: file.status,
                url: file.url,
                size: (file.size / 1024).toFixed(2) + " KB",
            }));
            setTotalFiles(data.total);
            handleToggleNotification(data.total);
            setUploadedFiles(filteredData);
            setIsFetching(false);
        } catch (error) {
            toast.error(error.response?.data?.detail);
            setIsFetching(false);
        }
    };

    const fetchFolders = async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/folders/${projectId}/`);
            setFolders(response.data);
        } catch (error) {
            toast.error("Failed to fetch folders.");
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedFiles((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((fileId) => fileId !== id) : [...prevSelected, id]
        );
    };
    const uncheck = (reset = false) => {
        if (reset) {
            setSelectedFiles([]); // Uncheck all checkboxes
        }
    };

    const handleMove = async () => {
        if (!selectedFolder) {
            toast.error("Please select a folder.");
            return;
        }
        try {
            await axiosInstance.post(`${API_URL}/v1/folders/${projectId}/assign-files/${selectedFolder}`, {
                file_ids: selectedFiles
            });
            toast.success("Documents moved successfully.");
            setShowMoveModal(false);
            setSelectedFiles([]);
            fetchData();
        } catch (error) {
            toast.error("Failed to move documents.");
        }
    };
    const handleToggleNotification = (totalFiles) => {
        if (totalFiles > 0) {
            dispatch(setDocumentsFalse());
        } else if (totalFiles <= 0) {
            dispatch(setDocumentsTrue());
        }
    };

    const handleCloseModal = () => {
        setFile(null);
    };
    const handleCloseDownloadModal = () => {
        setShowDownloadModal(false)
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e, type) => {

        e.preventDefault();
        setIsDragOver(false);
        let selectedFile;
        if (type === "drop") {
            selectedFile = e.dataTransfer.files[0];
        } else {
            selectedFile = e.target.files[0];
        }

        // Validate file format
        if (selectedFile && selectedFile.type !== 'application/pdf') {
            setErrorMessage('*Only .pdf files are allowed.');
            setFile(null);
            return;
        } else {
            // If valid, proceed with the upload
            setFile(selectedFile);
            setErrorMessage(''); // Clear any existing error message

            // Start the upload
            startUpload(selectedFile);
        }

    };


    function convertDateReverse(dateString) {
        const originalFormat = formatCustomDateTime(dateString);
        const [month, day, year] = originalFormat.replace(',', '').split(' ');
        return `${day} ${month} ${year}`; // Reverse the day and month without commas
    }

    const startUpload = async (uploadedFile) => {

        const maxSizeInBytes = 50 * 1024 * 1024;

        if (uploadedFile.size > maxSizeInBytes) {

            setFile(null);
            toast.error('File size exceeds the limit of 10 MB.');
            return;
        }

        if (!uploadedFile) return;

        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("folder_id", "");
        try {

            setIsUploading(true);
            const response = await axiosInstance.post(
                `${API_URL}/v1/user/documents/${projectId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },

                }
            );

            const data = response.data;

            const dateFetched = convertDateReverse(data.uploaded_on);
            setUploadedFiles([
                ...uploadedFiles,
                {
                    id: data.id,
                    name: data.name,
                    status: "Processing",
                    uploaded_on: data.uploaded_on,
                    url: data.url
                },
            ]);
            setTotalFiles(totalFiles + 1);
            handleToggleNotification(totalFiles + 1);
            setIsUploading(false);
            setModal(false);
        } catch (error) {
            if (error.response && error.response.status) {
                toast.error(error.response?.data?.detail);
            }
            setIsUploading(false);

        }

    };

    const handlePageChange = (page) => {
        setPageNum(page);
    }

    const handleDeleteDocument = async () => {
        setIsDeleting(true);
        try {
            const res = await axiosInstance.delete(`${API_URL}/v1/user/documents/${selectedFileId}`);
            const data = res.data;
            toast.success('Deleted Successfully');
            // Fetch the updated list of files after deletion
            const updatedFiles = uploadedFiles.filter(file => file.id !== selectedFileId);
            setUploadedFiles(updatedFiles);

            // If the current page becomes empty after deletion, move to the previous page
            if (updatedFiles.length === 0 && pageNum > 1) {
                setPageNum(prevPage => prevPage - 1);
            } else {
                fetchData(); // If there are still documents left, simply refresh the current page
            }
            setShowDeleteModal(false);
            setIsDeleting(false);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
            setIsDeleting(false);
        }

    };
    const handleDownloadClick = (doc) => {
        setSelectedFile(doc);
        console.log(selectedFile);
        // Store the file details
        setShowDownloadModal(true); // Show the modal
    };

    // Function to initiate the download inside the modal
    const handleConfirmDownload = () => {
        if (selectedFile) {
            const link = document.createElement('a');
            link.href = selectedFile.url;
            link.download = selectedFile.name;
            link.click();
        }
        setShowDownloadModal(false);
    };

    const handleViewClick = async () => {
        console.log(selectedFile);
        if (selectedFile) {
            setSelectedFileId(selectedFile.id);
            console.log(selectedFileId);
            // try {
            const res = await axiosInstance.get(`${API_URL}/v1/user/documents/view/${selectedFile.id}`);
            const url = res.data;
            setFileurl(url);
            console.log(url);
        }
        // } catch (error) {
        //     toast.error(`${error.response?.data?.detail}`);
        //     setIsDeleting(false);
        // }
        const timestamp = new Date().getTime();
        const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileurl)}&embedded=true&nocache=${timestamp}`;
        window.open(googleViewerUrl, '_blank', 'noopener,noreferrer');

    };
    return (
        <div
            className={` flex flex-col mt-28 items-center transition-all duration-300`}>

            {modal && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center"
                    onClick={() => setModal(false)} // Close modal when clicking outside
                >
                    <div
                        className={`bg-[#FFFFFF] mb-8 mr-20 h-[343px] rounded-lg shadow-md p-6 border-[1px] border-primary-700 border-opacity-50 w-[688px] bg-[#868CA21A] `}
                        onClick={(e) => e.stopPropagation()} // Prevent click propagation to the overlay

                    >
                        <div className="flex items-center justify-center mb-4">
                            {/* <span
                                className={`border-[1px] bg-secondary-400  border-opacity-50 text-white rounded-full p-[22px] ${isUploading && "hidden"
                                    } ${file ? "" : ""}`}
                            >
                                {/* <img src={file} alt="" /> */}
                            {/* </span> */}
                            {/* {isUploading && (
                                <img
                                    src={BouncingGif}
                                    alt="Uploading"
                                    className={`${isUploading && "h-20 w-20"}`}
                                />
                            )} */}
                        </div>
                        <h2
                            className={`${file ? "mb-4" : ""
                                } text-xl text-secondary-400 leading-normal font-dmsans capitalize font-bold text-center`}
                        >
                            {isUploading
                                ? "Uploading Your Document"
                                : file
                                    ? "Successfully Uploaded"
                                    : ""}
                        </h2>
                        <p
                            className={`${file && "hidden"
                                } text-secondary-400 text-opacity-50 font-dmsans text-base font-medium mb-2 text-center`}
                        >
                            {isUploading ? "Please wait while we upload your document" : ""}
                        </p>
                        {isUploading ? (
                            <div className="flex flex-col mb-4 w-full justify-center items-center">
                                <img
                                    src={LoadingBar3}
                                    alt="Loading..."
                                    className="w-64 h-[10px] p-0 m-0"
                                />
                                <div className="text-center text-[#868CA2] font-dmsans mt-3 text-xs">
                                    Please wait while we upload your document
                                </div>
                            </div>
                        ) : (
                            <div className={`flex justify-center ${file ? "mb-20" : ""}`}>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        handleDrop(e, "input-file");
                                        console.log("File input triggered");
                                    }}
                                    className="hidden"
                                    id="fileInput"
                                />
                                <img
                                    src={Upload}
                                    width={"150px"}
                                    height={"150px"}
                                    alt="Upload Icon"
                                    onClick={() => document.getElementById("fileInput").click()} // Trigger file input on icon click
                                    className="cursor-pointer"
                                />

                                {file && (
                                    <span className="ml-4 text-base font-bold font-dmsans text-secondary-400">
                                        {file.name}
                                    </span>


                                )}

                            </div>
                        )}
                        {errorMessage && (
                            <p className="text-red-500 text-xs text-center">{errorMessage}</p>
                        )}
                        {!isUploading && (
                            <div className="flex flex-col justify-center mt-10 items-center space-y-2">
                                <p className="text-[#000000] text-xl text-center">
                                    Click here to upload your file or drag and drop.
                                </p>

                                <div className="flex items-center text-center">
                                    <TbAlertTriangle className="mr-2 text-[#787878] text-opacity-50 w-[20px] h-[20px]" />
                                    <span className="text-[#787878] text-opacity-50 text-xs">
                                        Only PDF files are accepted, with a maximum file size of 10 MB.
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


            {/* end the modal for file upload ends*/}
            <div className="mx-auto w-full pb-[20px] relative ">
                {uploadedFiles?.length > 0 && <h2 className="text-[#000000] text-2xl font-semibold  mb-4 mt-6 text-left mx-8">
                    Files
                </h2>}

                <div className="overflow-x-auto  mx-8 mb-2">
                    {uploadedFiles?.length > 0 && <div className="flex items-center justify-between px-6 py-2 bg-[#F5F3F0]  h-[50px] text-[#2B2E3A] font-medium text-sm border-b border-gray-200">
                        {showMoveCheckbox && <div className="w-10"></div>}
                        <div className="w-[110px] text-center text-xl">Name</div>
                        <div className="flex-1  text-center ml-4 text-xl">Date</div>
                        <div className="w-[186px] text-xl">Status</div>
                        <div className="w-[90px] text-xl">Actions</div>
                    </div>}
                    {isFetching ? (
                        <DocumentListShimmer items={5} />
                    ) : (
                        uploadedFiles?.length === 0 && !isUploading ? (
                            <div className="flex flex-col justify-center items-center mt-10">
                                <img
                                    src={Emptydocuments}
                                    alt=""
                                // className={`${isUploading && "h-20 w-20"}`}
                                />
                                <div className="text-[#000000] font-dmsans mt-2">Upload your first document to get started!</div>
                            </div>
                        ) : (uploadedFiles.map((doc, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between px-4 py-4 bg-[#EFEEEA]  h-[60px]"
                            >
                                {showMoveCheckbox && (
                                    <input
                                        type="checkbox"

                                        checked={selectedFiles.includes(doc.id)}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                        className="mr-16 scale-150 "
                                    />
                                )}
                                {/* Date */}
                                <div className="flex items-center text-m text-[#2B2E3A] font-medium w-[150px] mr-4">
                                    {/* <img src={AssignmentIcon} alt="file icon" className="h-5 w-5" /> */}
                                    <span className="truncate text-center">
                                        {doc.name}
                                    </span>
                                </div>
                                <div className="flex flex-1 items-center justify-center gap-2 text-m font-medium text-[#2B2E3A] truncate">
                                    {doc.uploaded_on}
                                </div>

                                {/* Document Name */}


                                {/* File Size and Action Buttons */}
                                <div className="flex items-center space-x-16 ml-10">
                                    {/* File Size */}
                                    <div className={`flex justify-start items-center ${role === 'admin' ? ' w-[106px]' : ' w-[106px]'}`}>
                                        <img
                                            className="h-fit"
                                            src={statusSvgGenerator(
                                                `${doc.status === "Ready"
                                                    ? "#000000"
                                                    : doc.status === "Processing"
                                                        ? "#FFE500"
                                                        : "#FF2D2D"
                                                }`
                                            )}
                                            alt=""
                                        />
                                        <span className={`font-dmsans px-2 py-1 rounded-full  text-base`}>
                                            {doc.status}
                                        </span>
                                    </div>

                                    {/* Download Button */}


                                    <div className={`flex items-center  ${role === 'admin' ? 'space-x-14' : 'space-x-14'}`}>
                                        {/* Download Button */}
                                        <button
                                            className="text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] w-[30px] h-[30px] flex items-center justify-center"
                                            onClick={() => handleDownloadClick(doc)}
                                        >
                                            <FiEye size={20} />

                                        </button>


                                        {/* Delete Button */}

                                        <button className={`text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center w-[30px] h-[30px] ${role === 'admin' ? '' : ''}`} onClick={() => {
                                            setShowDeleteModal(true); setSelectedFileId(doc.id);
                                        }} data-testid="delete">
                                            <DeleteIcon className="  w-[22px] h-[22px]" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )))
                    )}
                    {isUploading && (
                        <div>
                            <DocumentListShimmer items={1} />
                        </div>
                    )}
                </div>
                {uploadedFiles.length > 0 && <div className="mx-8"> {/* Adjust this container to align pagination to the left */}
                    <DraftsPagination
                        currentPage={pageNum}
                        totalDrafts={totalFiles}
                        onPageChange={handlePageChange}
                    />
                </div>}
            </div>

            {file && !isUploading && (
                <SucessModal text={'Document uploaded Successfully'} handleModal={handleCloseModal} />

            )}

            {/* Show delete Modal */}
            {showDeleteModal && (
                <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center  h-[200px] w-[360px]">
                        <img src={FeaturedIcon} alt="" className="mb-2" />
                        <div className="text-[#101828] font-medium text-lg mb-2">Delete document</div>
                        <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-4 text-center">
                            Are you sure you want to delete this <br /> document? This action cannot be undone.
                        </p>
                        <div className="flex justify-center space-x-6 w-full">
                            <button
                                className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                                onClick={handleDelete}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleDeleteDocument}
                                disabled={isDeleting}
                                className="w-[150px] h-[39px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                            >
                                {isDeleting ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                                ) : (
                                    "Delete"
                                )}
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}

            {/* Download Confirmation Modal */}
            {showDownloadModal && (
                <PopupModal closeModal={handleCloseDownloadModal} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
                        <div className="flex flex-col items-center space-y-7">
                            {/* <FiEye size={20} /> */}
                            <p className="text-lg font-medium  text-center">Do you want to view this file?</p>
                            <div className="flex space-x-4">
                                <button
                                    className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                                    onClick={handleCloseDownloadModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-3 w-[150px] h-[44px] rounded-md  font-dmsans bg-[#96F3C3] text-base text-[#000000] font-medium flex items-center justify-center"
                                    onClick={handleViewClick}
                                >
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                </PopupModal>

            )}

            {selectedFiles.length > 0 && showMoveCheckbox && (
                <div className="fixed bottom-10  w-[280px] bg-white  border-black py-4 px-6 flex justify-end items-center">
                    <span className="mr-4">{selectedFiles.length} file(s) selected</span>
                    <button
                        onClick={() => setShowMoveModal(true)}
                        className="bg-primary-700 text-black px-4 py-2 rounded-full"
                    >
                        Move
                    </button>
                    <button
                        onClick={() => uncheck(true)}
                        className="bg-primary-700 text-black px-4 py-2 rounded-full"
                    >
                        Cancel
                    </button>
                </div>
            )}



            {/* Move Modal */}
            {/* Move Modal */}
            {/* Move Modal */}
            {showMoveModal && (
                <PopupModal closeModal={() => setShowMoveModal(false)} className="bg-[#F7F5F3] rounded-lg shadow-lg w-[400px] p-6">
                    <div className="flex flex-col space-y-6">
                        <h3 className="text-lg font-semibold text-center">Move {selectedFiles.length} item{selectedFiles.length > 1 ? "s" : ""} from files</h3>
                        <div className="flex flex-col ">
                            {folders.map((folder) => (
                                <div
                                    key={folder.id}
                                    onClick={() => setSelectedFolder(folder.id)}
                                    className={`flex items-center justify-between p-3 border rounded-lg cursor-pointer ${selectedFolder === folder.id ? "border-black bg-gray-100" : "border-[#F7F5F3]"
                                        } hover:border-black`}
                                >
                                    <div className="flex items-center">
                                        <FaFolder
                                            size={20} className=" text-black" />
                                        <span className="text-base font-medium px-5">{folder.name}</span>
                                    </div>
                                    {/* <span className="text-sm text-gray-500">{folder.itemCount} item{folder.itemCount > 1 ? "s" : ""}</span> */}
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 w-full rounded-md border border-gray-300 text-gray-700 hover:bg-gray-100"
                                onClick={() => setShowMoveModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 w-full rounded-md bg-black text-white disabled:opacity-50"
                                onClick={handleMove}
                                disabled={!selectedFolder}
                            >
                                Move
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}


        </div>
    );
};

export default DocumentsPage;
