import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-white py-8 border-t border-gray-200">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Logo and Description */}
                    <div>
                        <h2 className="text-xl font-bold text-gray-900">Pragya</h2>
                        <p className="mt-4 text-gray-600">
                            Ready to grow your business with the power of AI?
                        </p>
                        <button className="mt-4 px-6 py-2 bg-black text-white rounded-md">
                            Get started
                        </button>
                    </div>

                    {/* Product Links */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">Product</h3>
                        <ul className="mt-4 space-y-2">
                            <li><a href="#home" className="text-gray-600 hover:text-gray-900">Home</a></li>
                            <li><a href="#features" className="text-gray-600 hover:text-gray-900">Features</a></li>
                            {/* <li><a href="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
                            <li><a href="#why-us" className="text-gray-600 hover:text-gray-900">Why Choose Us</a></li>
                            <li><a href="#testimonials" className="text-gray-600 hover:text-gray-900">Testimonials</a></li> */}
                            <li><a href="#faq" className="text-gray-600 hover:text-gray-900">FAQ</a></li>
                        </ul>
                    </div>

                    {/* Resources Links */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">Resources</h3>
                        <ul className="mt-4 space-y-2">
                            <li><a href="#blog" className="text-gray-600 hover:text-gray-900">Blog</a></li>
                            <li><a href="#help-center" className="text-gray-600 hover:text-gray-900">Help Center</a></li>
                            {/* <li><a href="#guides" className="text-gray-600 hover:text-gray-900">Guides</a></li>
                            <li><a href="#affiliate-program" className="text-gray-600 hover:text-gray-900">Affiliate Program</a></li> */}
                        </ul>
                    </div>

                    {/* Legal Links */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">Legal</h3>
                        <ul className="mt-4 space-y-2">
                            <li><Link to="/policy" className="text-gray-600 hover:text-gray-900">Privacy Policy</Link></li>
                            <li><Link to="/terms" className="text-gray-600 hover:text-gray-900">Terms & Conditions</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
